.eu-residents-container {
  margin: 32px 0;
  & p {
    margin: 0;
    line-height: 30px;
  }
  .radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    .radio-option {
      &_label-container {
        align-items: flex-start;
        input[type="radio"] {
          margin-top: 6px;
        }
      }
    }
  }
  .radio-group-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    :nth-child(2) {
      margin-left: 12px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 0;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 0;
      & > div {
        margin: 12px 0;
      }
    }
  }

  .checkbox {
    margin: 10px 0 !important;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin: 8px 0 !important;
    }
    .checkbox-label {
      cursor: pointer;
      margin: 0 !important;
      &__text {
        margin-left: 12px;
        height: 100%;
        width: 100%;
        & > span {
          text-decoration: none;
          color: map-get(map-get($paletteNew, secondary), royal);
        }
      }
    }
    .checkmark {
      margin-top: 3px;
    }
  }
  .content-separator {
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray4);
    margin: 32px 0;
  }

  &__experienced-investor-options {
    margin-top: 60px;
  }
}
