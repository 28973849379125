.rewards-container {
  .rewards-header {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      display: block;
      flex-direction: column;
      text-align: center;
    }
    .my-bucks {
      color: map-get(map-get($paletteNew, secondary), royal);
      border: 1px solid map-get(map-get($paletteNew, secondary), royal);
      height: 47px;
      padding: 0px 20px;
      white-space: nowrap;
      display: flex;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 12px auto;
        display: inline-block;
        height: 40px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        height: 32px;
      }
    }
  }

  .title-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    .title {
      display: flex;
      align-items: center;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 90%;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0.9rem;

      .title {
        margin-bottom: 1rem;
      }
    }
  }

  .rewards-share-container {
    background-color: map-get(map-get($paletteNew, gray), gray1);

    padding: 30px 36px 36px 36px;
    border-radius: 8px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 25px;
    }
    .rewards-share-inner {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        align-items: flex-start;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column;
        align-items: center;
      }
    }

    .note {
      margin-top: 8px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 0px;
        margin-bottom: 5px;
      }
    }

    .rewards-share-img {
      min-width: 196px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        min-width: 156px;
      }
    }

    .rewards-info {
      margin-top: 15px;
    }
  }

  .rewards-share {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title-group {
      margin-bottom: 1rem;

      @media screen and (max-width: map-get($breakpoints, small)) {
        align-items: flex-start;
      }
    }

    .share-text {
      color: map-get(map-get($paletteNew, gray), gray3);
      height: 15px;
      position: absolute;
      right: 16px;
      top: 12px;
      cursor: default;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        right: 0px;
        top: 44px;
      }
    }
  }

  .input-share-container {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
    }

    .actions-share {
      padding-top: 5px;

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 20px;
        text-align: center;
        padding-top: 0px;
      }
    }

    .share-btn {
      width: 30px;
      height: 30px;
      background: map-get(map-get($palette, primary), 3);
      border: 0;
      outline: 0;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 5px;
      transition: all ease-in-out 0.1s;
      cursor: pointer;

      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 40px;
        height: 40px;
        margin: 0 6px;
      }

      img {
        width: 18px;
        height: 18px;
        transition: all ease-in-out 0.1s;
      }

      &:hover {
        background: rgba(map-get(map-get($palette, primary), 3), 1);
      }

      &:hover img {
        transform: scale(1.1);
      }
    }
    &.disabled {
      .input-share {
        input {
          color: map-get(map-get($paletteNew, gray), gray3);
          cursor: default;
          outline: none;
        }

        .ghost-input {
          cursor: default;
        }
      }
      .share-btn {
        cursor: default;
        img {
          opacity: 0.2;
        }
      }
      .copy-btn {
        cursor: default;
        opacity: 0.3;
      }
    }
  }

  .input-share {
    position: relative;
    margin-right: 5px;
    width: 70%;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 66%;
      margin-right: 0px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin-right: 0px;
    }

    input[type='text'] {
      width: 100%;
      height: 40px;
      min-height: 40px;
      background: map-get(map-get($palette, primary), 3);
      border-radius: 8px;
      border: 0;
      padding: 11px 16px;
      text-overflow: ellipsis;
      cursor: pointer;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        min-width: 100%;
        padding: 11px 35px 11px 11px;
      }
    }

    .copy-btn {
      width: 30px;
      height: 30px;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 5px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        right: 5px;
      }

      img {
        width: 20px;
        height: 20px;
        transition: all ease-in-out 0.1s;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }

    .ghost-input {
      position: absolute;
      width: 100%;
      height: 30px;
      display: block;
      top: 11px;
      left: 16px;
      opacity: 0;
      cursor: pointer;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        top: 13px;
        left: 11px;
        white-space: nowrap;
        overflow: hidden;
        width: 90%;
      }
    }

    .ghost-input[data-copied='1'] {
      opacity: 0;
      animation-name: ghosting;
      animation-duration: 0.9s;
    }
  }

  .rewards-steps {
    margin-top: 30px;
    margin-bottom: 48px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-bottom: 30px;
    }

    .rewards-steps-title {
      margin-bottom: 5px;
    }
  }

  .desktop-hidden {
    display: none;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      display: block;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    .contact-support-container {
      justify-content: center;
    }
  }
}

@keyframes ghosting {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translate(0, -18px);
  }
}
