$bold: 700;
$semi-bold: 600;
$medium: 500;
$regular: 400;
$thin: 300;
// Title
.title_1 {
  font-family: 'CodecColdBold' !important;
  font-size: 60px;
  line-height: 72px;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 32px;
    line-height: 40px;
  }
}
.title_2 {
  font-family: 'Source Serif 4' !important;
  font-size: 60px;
  line-height: 72px;
  font-weight: $semi-bold;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 32px;
    line-height: 40px;
  }
}
// Heading
h1,
.heading_1 {
  font-family: 'CodecColdBold' !important;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 36px;
    line-height: 40px;
  }
}
h2,
.heading_2 {
  font-family: 'Source Serif 4' !important;
  font-size: 42px;
  line-height: 48px;
  font-weight: $semi-bold;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 36px;
    line-height: 40px;
  }
}
h3,
.heading_3 {
  font-family: 'CodecColdBold' !important;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 30px;
    line-height: 34px;
  }
}
h4,
.heading_4 {
  font-family: 'Source Serif 4' !important;
  font-size: 36px;
  line-height: 40px;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 30px;
    line-height: 34px;
  }
}
h5,
.heading_5 {
  font-family: 'CodecColdExtraBold' !important;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 30px;
    line-height: 34px;
  }
}
h6,
.heading_6 {
  font-family: 'CodecColdExtraBold' !important;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 18px;
    line-height: 24px;
  }
}
.heading_7 {
  font-family: 'CodecColdBold' !important;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 18px;
    line-height: 24px;
  }
}
.heading_8_eyebrow {
  font-family: 'Sofia Sans Condensed' !important;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  @media screen and (max-width: map-get($breakpoints, small)) {
    font-size: 20px;
    line-height: 26px;
  }
}
// body
.b_22_semibold {
  font-family: 'Source Sans Pro';
  font-size: 22px;
  line-height: 28px;
  font-weight: $semi-bold;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 20px;
    line-height: 26px;
  }
}
.b_22_regular {
  font-family: 'Source Sans Pro';
  font-size: 22px;
  line-height: 28px;
  font-weight: $regular;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 20px;
    line-height: 26px;
  }
}
.b_18_semibold {
  font-family: 'Source Sans Pro';
  font-size: 18px;
  line-height: 24px;
  font-weight: $semi-bold;
  letter-spacing: 0.01em;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 16px;
    line-height: 22px;
  }
}
.b_18_regular {
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: $regular;
  line-height: 24px;
  color: map-get(map-get($paletteNew, gray), gray4);
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 16px;
    line-height: 22px;
  }
}
.b_16_semibold {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 22px;
  font-weight: $semi-bold;
  letter-spacing: 0.01em;
}
.b_16_regular {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 22px;
  font-weight: $regular;
  letter-spacing: 0.01em;
  color: map-get(map-get($paletteNew, gray), gray4);
}
// disclaimer and footnote
.b_14_regular {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 18px;
  font-weight: $regular;
  letter-spacing: 0.01em;
}

$invest-hero-desktop: 60px;
$invest-hero-mobile: 32px;
