.trusted-contact-container {
  margin: 48px 0;
}

.expandable-title {
  cursor: pointer;

  img {
    margin-left: 5px;
  }
}

.remove-link {
  cursor: pointer;
  color: map-get(map-get($paletteNew, secondary), royal);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 5px;
  }
  &:hover {
    color: map-get(map-get($paletteNew, secondary), royal);
  }
  &.remove-link-disabled {
    cursor: default;
    color: map-get(map-get($paletteNew, gray), gray3);
  }
}
