.order-confirmed-container {
  max-width: 672px;
  margin: 94px auto 16px auto;
  padding: 16px;
  background: #ffffff;
  box-shadow:
    0px 0px 10px rgba(5, 5, 5, 0.08),
    inset 0px 0px 0px 1px rgba(5, 5, 5, 0.08);
  border-radius: 8px;

  @media screen and (max-width: map-get($breakpoints, small)) {
    margin: 24px auto 16px auto;
  }
  .order-confirmed-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      padding: 4px 0;
    }
  }
}

.order-confirmed-cta {
  max-width: 672px;
  margin: 0px auto;
  padding: 0px 16px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 0px;
  }
}
