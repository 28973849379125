.invest-container {
  .inner-container {
    max-width: 1100px;
  }
  .invest-header {
    display: flex;
    flex-direction: column;
  }
  .invest-title {
    color: map-get(map-get($paletteNew, data), indigo);
    display: flex;
    justify-content: center;
    margin: 48px 0 18px 0;
    @media screen and (max-width: map-get($breakpoints,small)) {
      text-align: center;
      margin: 24px 0 12px 0;
    }
  }
  .invest-subtitle {
    width: 65%;
    text-align: center;
    align-self: center;
    margin: 18px 0 48px 0;
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 100%;
      margin: 12px 0 24px 0;
    }
  }
  .content {
    color: map-get(map-get($paletteNew, gray), gray4);
  }

  .product-filters {
    margin-bottom: 48px;
    .unicrons-filters-search {
      position: relative;
      .input-group {
        margin-bottom: 0;
        margin-top: 5px;
      }
      input[type='text'] {
        padding: 8px 35px 8px 12px;
        width: 100%;
      }
      img {
        position: absolute;
        top: 15px;
        right: 12px;
        opacity: 0.7;
      }
    }

    select {
      width: 100%;
      margin-top: 5px;
    }
  }
  .invest-subheader {
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
    margin-bottom: 16px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      p {
        margin-top: 8px;
      }
    }
  }
  .invest-card-group {
    margin-bottom: 48px;
    min-height: 100px;
    .no-result-text {
      color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
  .product-card {
    display: grid;
    grid-template-columns: 1.25fr 3fr 0.75fr;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 12px;
    background: map-get(map-get($paletteNew, gray), white);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    height: 105px;

    .product-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: map-get(map-get($paletteNew, gray), gray5);
      // NOTE: this is a stand alone
      &__sector {
        color: map-get(map-get($paletteNew, gray), gray3);
        font-size: 14px;
      }
      &__icon {
        height: 60px;
        width: 150px;
        object-fit: contain;
        margin-left: -26px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-left: 0;
          height: 35px;
          width: 100px;
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        align-items: flex-start;
      }
    }

    .product-description {
      display: flex;
      flex-direction: column;
      color: map-get(map-get($paletteNew, gray), gray4);
      border-left: 1px solid map-get(map-get($paletteNew, gray), gray2);
      border-right: 1px solid map-get(map-get($paletteNew, gray), gray2);
      padding: 0 30px;
      margin-top: -4px;
      &__sector {
        color: map-get(map-get($paletteNew, gray), gray3);
        font-size: 18px;
      }
      &__description {
        color: map-get(map-get($paletteNew, gray), gray5);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        height: auto;
        overflow: auto;
      }
    }
    //NOTE: this is stand alone style
    .product-share-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &__share-price {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: $bold;
        line-height: 24px; /* 100% */
        color: map-get(map-get($paletteNew, primary), connectedCobalt);
      }
      &__share-price-copy {
        color: map-get(map-get($paletteNew, gray), gray3);
        text-align: center;
        font-family: 'CodecColdRegular' !important;
        font-size: 12px;
        font-style: normal;
        font-weight: $regular;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.12px;
        &.medium {
          font-weight: $semi-bold;
        }
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        border-left: 1px solid map-get(map-get($paletteNew, gray), gray2);
      }
    }
    &:hover {
      background: map-get(map-get($paletteNew, gray), gray1);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: grid;
      grid-template-columns: 1.25fr 0.75fr;
      padding: 16px;
    }
  }
  // NOTE: stand alone style for tag
  .flag {
    height: 20px;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 10px;
    left: 0;
    right: 0;
    position: absolute;
    top: -8px;
    margin-left: auto;
    margin-right: auto;
    font-weight: $semi-bold;
    color: map-get(map-get($paletteNew, gray), white);
    z-index: 100;
    &.recently_added {
      background-color: map-get(map-get($paletteNew, primary), connectedCobalt);
    }
    &.top_seller {
      background-color: map-get(map-get($paletteNew, accent), unicorn);
    }
    &.fully_subscribed {
      background-color: map-get(map-get($paletteNew, gray), gray3);
    }
    &.limited_shares {
      background-color: map-get(map-get($paletteNew, primary), freshMint);
      color: map-get(map-get($paletteNew, primary), accessibleIndigo);
    }
    &.coming_soon {
      background-color: map-get(map-get($paletteNew, primary), openOcean);
      color: map-get(map-get($paletteNew, primary), accessibleIndigo);
    }
  }

  .loader-component {
    margin-top: 32px;
  }
}

.team-apollo-modal {
  ul {
    padding: 0 24px;
  }
}
