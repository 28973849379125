@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:opsz,wght@8..60,400;8..60,500;8..60,600;8..60,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans+Condensed:wght@400;500;600;700&display=swap');
// NOTE: since we are using max-width in our styling approach
// small => anything till tablet size 767
// medium => anything till desktop size 1023
// large => anything till large desktop size 1439
// smallest size product care about is 360px for mobile
$breakpoints: (
  small: 767px,
  medium: 1023px,
  large: 1439px
);
// TODO: rename
@import './_colorNew.scss';
@import './_fontNew.scss';
// TODO: remove color.scss
@import './color.scss';

@font-face {
  font-family: 'CodecColdRegular';
  src:
    url('../fonts/Codec-Cold/Woff2/Codec-Cold-Regular.woff2') format('woff2'),
    url('../fonts/Codec-Cold/Woff/Codec-Cold-Regular.woff') format('woff'),
    url('../fonts/Codec-Cold/Ttf/Codec-Cold-Regular.ttf') format('truetype'),
    url('../fonts/Codec-Cold/Otf/Codec-Cold-Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'CodecColdBold';
  src:
    url('../fonts/Codec-Cold/Woff2/Codec-Cold-Bold.woff2') format('woff2'),
    url('../fonts/Codec-Cold/Woff/Codec-Cold-Bold.woff') format('woff'),
    url('../fonts/Codec-Cold/Ttf/Codec-Cold-Bold.ttf') format('truetype'),
    url('../fonts/Codec-Cold/Otf/Codec-Cold-Bold.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'CodecColdExtraBold';
  src:
    url('../fonts/Codec-Cold/Woff2/Codec-Cold-Extrabold.woff2') format('woff2'),
    url('../fonts/Codec-Cold/Woff/Codec-Cold-Extrabold.woff') format('woff'),
    url('../fonts/Codec-Cold/Ttf/Codec-Cold-Extrabold.ttf') format('truetype'),
    url('../fonts/Codec-Cold/Otf/Codec-Cold-Extrabold.otf') format('opentype');
  font-weight: 600;
}

// NOTE: need to think about what to do there.
*,
body,
html {
  font-family: 'Source Sans Pro', 'Source Serif 4' !important;
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  -webkit-tap-highlight-color: transparent;
  @media screen and (max-width: map-get($breakpoints,small)) {
    font-size: 16px;
  }
}

html {
  box-sizing: border-box;
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  color: map-get(map-get($paletteNew, gray), gray5);
  overflow-x: hidden;
  background: map-get(map-get($palette, primary), 3);
  height: 100%;
  width: 100%;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(map-get(map-get($paletteNew, gray), gray5), 0.25);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
  }
  &::-webkit-scrollbar-track {
    background: rgba(map-get(map-get($paletteNew, gray), gray5), 0.1);
    border-radius: 0;
  }
}

*,
:after,
:before {
  box-sizing: inherit;
}

::selection {
  background-color: map-get(map-get($paletteNew, gray), gray1);
  color: rgba(map-get(map-get($paletteNew, gray), gray5), 0.87);
}

@import './layout/nav.scss';
@import './layout/footer.scss';
@import './onboarding/signup.scss';
@import './onboarding/signin.scss';
@import './onboarding/activate.scss';
@import './onboarding/verify.scss';
@import './profile/profile.scss';
@import './profile/trustedContact.scss';
@import './profile/_progressbar.scss';
@import './confirmOrder/order-modal.scss';
@import './orderDetail/order-detail.scss';
@import './portfolio/portfolio.scss';
@import './portfolio/_dropdown.scss';
@import './confirmOrder/order-confirm';
@import './wallet/wallet.scss';
@import './wallet/linqtoWallet.scss';
@import './confirmOrder/error-modal';
@import './contact.scss';
@import './semantic.scss';
@import './not-found';
@import './investorStatus/investorStatus.scss';
@import './linqto.scss';
@import './Security/security';
@import './autoVerification';
@import './wallet/transfer-modal';
@import './rewards/_rewards.scss';
@import './imagecropper/_imagecropper.scss';
@import './manualverification/_manualverification.scss';
@import './unicorns/_unicorns';
@import './box.scss';
@import './accountBanner/_accountBanner.scss';
@import './invest/_invest.scss';
@import './invest/_invest-details.scss';
@import './identity.scss';
@import './origination/_origination.scss';
@import './origination/_origination-details.scss';
@import './scheduleMeetingsButton/_scheduleMeetingsButton.scss';
@import './breadcrumb/_breadcrumb.scss';
@import './sellOffer/_sellOffer.scss';
@import './riaDashboard/_riaDashboard.scss';
@import './advisorTermsOfUse/_advisorTermsOfUse.scss';
@import './promoBanner/_promoBanner.scss';
@import './_divider.scss';
@import './_pageLoading.scss';
@import './_form.scss';
@import './_grid.scss';
@import './addFunds/_addFunds';
@import './sellHowItWorks/_sellHowItWorks';
@import './_nonClickableGrid.scss';
@import './promoInput/_promoInput.scss';

// TODO: from semantic UI
.ui.dimmer.modals.visible.active {
  height: 100%;
  position: fixed;
  overflow: hidden;
  overflow-y: hidden;
  margin: 0;
  bottom: 0 !important;
  right: 0 !important;
}
// TODO: from semantic UI
.ui.form .field.field {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px map-get(map-get($paletteNew, gray), gray1) inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

// TODO: from semantic UI
.ui.form {
  .selecting {
    select {
      box-shadow: 0 0 0px 1000px map-get(map-get($paletteNew, gray), gray1)
        inset !important;
      transition: background-color 5000s ease-in-out 0s;
      border: none;
      &:focus {
        outline: none;
      }
    }
    .arrow {
      font-size: 16px;
      color: map-get(map-get($paletteNew, gray), gray4);
      padding: 0 0 2px;
      /*left line */
      top: -28px;
      right: -90%;
      height: 0px;
      width: 20px;
      position: relative;
      pointer-events: none;
      img {
        width: 20px;
      }
    }
  }
}

hr {
  margin-top: 24px;
  margin-bottom: 16px;
  color: map-get(map-get($paletteNew, gray), gray4);
  border-width: 0.5px;
}
