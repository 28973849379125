.signup-container {
  .signup-header-container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column-reverse;
    }
  }
  .signup-header {
    margin-bottom: 36px;
    margin-top: 60px;
    color: map-get(map-get($paletteNew, gray), gray5);
    text-align: left;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 0px;
      margin-bottom: 16px;
    }
  }

  .signup-sub-header {
    margin-bottom: 16px;
    color: map-get(map-get($paletteNew, gray), gray4);
  }

  .signup-form {
    padding-left: 40px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding-left: 0px;
    }
  }

  .signup-reward-container {
    background-color: map-get(map-get($paletteNew, gray), gray1);
    display: flex;
    padding: 40px 32px;
    border-radius: 16px;
    align-items: center;
    margin-top: 30px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 20px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 16px 24px;
      margin: -25px;
      margin-top: -64px;
      border-radius: 0;
      margin-bottom: 46px;
    }

    &.partner-signup-reward-container {
      background-color: map-get(map-get($palette, primary), 3);
      box-shadow:
        0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
        inset 0px 0px 0px 1px
          rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
      padding: 0px 36px 0px 0px;

      img {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          height: 100%;
        }
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        min-height: 72px;
        max-height: 78px;
        margin: 0;
        border-radius: 8px;
        margin-bottom: 15px;
      }
    }

    img {
      width: 110px;
      margin-right: 20px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 67px;
      }
    }
  }

  .signup-terms {
    color: map-get(map-get($paletteNew, gray), gray4);
  }
}

.account-signin {
  color: map-get(map-get($paletteNew, gray), gray4);
  display: flex;
  justify-content: center;
  margin-top: 6px;
  align-items: center;
}

.signup-accreditation {
  margin: 8px 0 16px;
  color: map-get(map-get($paletteNew, gray), gray4);
}

.signup-oath-container {
  text-align: center;
  color: map-get(map-get($paletteNew, gray), gray4);
}

.signup-oath-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 0px;
  width: 434px;
  height: 48px;
  background: map-get(map-get($palette, primary), 3);
  border: 1px solid map-get(map-get($paletteNew, gray), gray1);
  box-shadow:
    0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0px 0px 0px 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  border-radius: 8px;
  color: map-get(map-get($paletteNew, gray), gray4);
  margin-bottom: 16px;
  cursor: pointer;
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
  }

  img {
    margin-right: 6px;
  }
  &.apple-oath-btn {
    img {
      margin-right: 4px;
      margin-left: 8px;
    }
  }
}
.signup-oath-user {
  margin-bottom: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: 0px;
  }
  .signup-oath-user-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 10px;
    }
  }
}

.error.message {
  a {
    text-decoration: none;
    color: map-get(map-get($paletteNew, secondary), royal);
  }
}
