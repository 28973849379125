.security-container {
  .mfa-group {
    .item {
      display: flex;
      flex-direction: row;
      border: none;
      padding: 1rem 0;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
      .info-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin: 10px 0px;
        }
      }
      span {
        margin-left: 3rem;
        background-color: map-get(map-get($palette, primary), 3);
        border-radius: 8px;
        padding: 5px 10px;
        color: map-get(map-get($paletteNew, gray), gray4);
        cursor: pointer;
        &:hover {
          background-color: map-get(map-get($paletteNew, gray), gray1);
        }
        &.remove {
          margin-left: 1rem;
          &:hover {
            color: map-get(map-get($palette, status), 1);
          }
        }
        &.unavailable {
          cursor: default;
          background-color: map-get(map-get($paletteNew, gray), gray1);
        }
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin-left: 0px;
          margin: 10px 0px;
          &.remove {
            margin-left: 0px;
          }
        }
      }
    }
  }
  .item-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-left: 2rem;
    }
  }
  .display:last-child {
    border-right: none;
  }

  .wrap {
    display: inline-block;
    position: relative;
    display: flex;
  }
  .box-title.mfa-auth-box-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding: 16px 24px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 16px 0px;
    }
    & > span {
      color: map-get(map-get($paletteNew, gray), gray4);
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin: 0.5rem 0 !important;
      }
    }
  }
  &.page-container {
    padding-bottom: 24px;
  }
}

.mfa-modal {
  .security-loading {
    width: 100%;
  }
  .security {
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100% !important;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: visible;
    .item-group {
      justify-content: center;
    }
    form {
      margin-top: 0;
      text-align: center;

      .input-group {
        margin: 1.1rem 0;
      }
      input:not(.PhoneInputInput) {
        letter-spacing: 5px;
        text-align: center;
        width: 200px;
        margin: 0 auto;
      }

      .input-group.no-label label {
        display: none;
      }
      .input-group.error:not(.checkbox) .err-msg {
        margin: 0 auto;
        text-align: center;
      }
    }
    label {
      display: block;
      text-align: center;
    }
    .note {
      margin: 1em 0 0;
      text-align: center;
      color: map-get(map-get($paletteNew, gray), gray4);

      span {
        cursor: pointer;
        color: map-get(map-get($palette, primary), 4);
        text-decoration: underline;
        &.time-counter {
          color: map-get(map-get($paletteNew, gray), gray5);
          cursor: default;
        }
      }
    }
    .content {
      padding: 10px 20px;
      text-align: center;
      margin: 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        padding: 20px 0;
        &.left-align {
          padding: 10px 0;
          text-align: left;
        }
      }
    }
  }
  .loader-wrapper {
    height: 230px !important;
    width: 420px !important;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100% !important;
    }
  }
  ul {
    margin-left: 20px;
  }
}

.delete-account {
  color: map-get(map-get($paletteNew, secondary), royal);
  margin-top: 60px;
  display: inline-block;
}

.delete-account-box {
  padding: 48px 117px;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 48px 95px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 0px;
  }

  .input-group {
    input[type='text'] {
      margin-top: 8px;
      max-width: 439px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        max-width: 100%;
      }
    }
  }
  .radio-group {
    margin-top: 24px;
    .radio-option label {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
  }
  .cta-container {
    width: 439px;
    margin-top: 16px;
    display: flex;
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 100%;
    }
    .btn {
      width: 100%;
      max-width: 100%;
    }
  }
  .radio-option_label-container {
    margin: 0 !important;
    & > input {
      margin-top: 2px !important;
      margin-right: 12px !important;
    }
  }
}

.confirm-delete-modal {
  .content {
    margin-bottom: 24px;
  }
}
