.order-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  background-color: map-get(map-get($palette, primary), 3);
  &.modal {
    .modal-inner-content {
      text-align: left;
    }
  }
  .order-modal-upload-wrapper {
    margin-top: -27px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    .order-modal-upload-wrapper-revs {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .order-modal-account-header {
    display: flex;
    align-items: center;
    padding: 0px 16px 16px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 0px 16px;
    }
    div {
      align-items: center;
      display: flex;
      img {
        margin-right: 16px;
        width: 40px;
        height: 40px;
      }
    }
    .note {
      color: map-get(map-get($paletteNew, gray), gray4);
      margin-left: 16px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
  .order-uphold-error {
    color: map-get(map-get($paletteNew, gray), gray4);
    margin-top: 10px;
  }
  .order-modal-account-item {
    border: 1px solid map-get(map-get($paletteNew, gray), gray1);
    box-shadow:
      0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0px 0px 0px 1px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    cursor: pointer;

    div {
      align-items: center;
      display: flex;
      img.account-icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }

      .order-modal-account-desc {
        color: map-get(map-get($paletteNew, gray), gray5);
      }

      .account-arrow {
        margin-left: 24px;
        margin-right: 8px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-left: 6px;
          margin-right: 0px;
        }
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

.insufficient-funds {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    width: 100%;
  }
}

.mb-4-0 {
  margin-bottom: 4px !important;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 0px !important;
  }
}
