.document-types-modal {
  .doc-title {
    color: map-get(map-get($paletteNew, gray), gray5);
    margin-top: 8px;
  }
  sup {
    color: map-get(map-get($paletteNew, secondary), royal);
    vertical-align: top;
    top: 14px;
  }
  .doc-list {
    margin-left: 32px;
  }
  .doc-type {
    color: map-get(map-get($paletteNew, gray), gray5);
    margin-top: 32px;
    display: block;
    text-align: center;
  }
  .doc-desc {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}

.document-success-modal {
  .content {
    margin-bottom: 24px;
  }
  .btn {
    min-width: 340px;
  }
}
