.no-reg-container {
  position: relative;
  margin: 24px 0;
  span,
  li,
  label {
    margin: 24px 0;
  }
  p {
    margin-left: 12px;
  }
  &_ordered-list-container {
    margin-bottom: 8px;
    padding: 0 20px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 0 12px;
    }
  }

  .checkbox-label {
    display: flex;
    margin: 0;
    .checkmark {
      margin-top: 2px;
    }
    &__text {
      margin: 0 0 0 12px;
      cursor: pointer;
    }
  }
}
