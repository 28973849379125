.fa-container {
  .header {
    color: map-get(map-get($paletteNew, gray), gray4);
  }
  ol {
    counter-reset: list;
    padding-left: 28px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-left: 22px;
    }
  }
  ol > li {
    list-style: none;
  }
  ol > li:before {
    content: counter(list, lower-alpha) ') ';
    counter-increment: list;
  }
  h3 {
    margin: 24px 0;
  }
  .fa-checkbox.checkbox {
    margin-bottom: -4px;
    margin-top: 16px;
    width: 100%;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: -8px;
    }
  }
  .fa-checkbox.input-group.checkbox .checkmark {
    position: absolute !important;
    left: 0;
    top: auto;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border: 1.5px solid map-get(map-get($paletteNew, gray), gray3);
    &:after {
      left: 4px;
    }
  }
  .checkbox-content {
    color: map-get(map-get($paletteNew, gray), gray4);
    display: flex;
    flex-direction: row;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: inline-block;
      line-height: 1;
    }
  }
  .submit-success-header {
    color: map-get(map-get($paletteNew, gray), gray5);
    margin-bottom: 24px !important;
  }
  .FA-submit-success.content {
    color: map-get(map-get($paletteNew, gray), gray5);
  }
  .ui.grid {
    margin-top: 8px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-right: -0.5rem;
      margin-left: -0.5rem;
    }
  }
  .ui.grid > .column:not(.row) {
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }
  .link.primary {
    margin-top: -3px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: inline-block;
      text-align: left;
      line-height: 48px;
      min-height: 0;
      width: fit-content;
    }
  }
}
