.dropdown-container {
  display: flex;
  height: 65px;
  position: relative;

  .dropdown-icon {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .item {
    display: flex;
    height: 100%;
    cursor: pointer;
  }
  .dropdown_menu {
    z-index: 100;
    position: absolute;
    right: 0;
    flex-direction: column;
    top: 40px;
    background-color: map-get(map-get($palette, primary), 3);
    border-radius: 4px;
    border: 1px solid map-get(map-get($paletteNew, gray), gray3);
    width: 215px;
    display: none;
    padding: 8px 16px;
  }
  .dropdown:hover > .dropdown_menu {
    display: block;
  }
  .dropdown:hover > .dropdown-icon {
    background-color: map-get(map-get($paletteNew, gray), gray1);
  }

  .dropdown_menu-6 {
    animation: growDown 250ms ease-in-out forwards;
    transform-origin: top center;
  }
  @keyframes growDown {
    0% {
      transform: scale(0.5);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  .dropdown {
    .item {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: map-get(map-get($paletteNew, gray), gray5);
      margin-bottom: 10px;
      padding-left: 30px;
      img {
        position: absolute;
        left: 5px;
      }
      &:hover {
        border: none;
        color: map-get(map-get($paletteNew, gray), gray5);
      }
      &.active {
        opacity: 0.8;
        box-shadow: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
