.page-container {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  padding: 64px 150px;
  @media screen and (max-width: map-get($breakpoints,large)) {
    padding: 64px 60px;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 64px 40px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 48px 24px;
    .image {
      width: 100%;
    }
  }
  &.page-with-breadcrumb {
    padding-top: 32px;
    .top-banner-contanier {
      margin-top: 0px;
    }
  }
}

.inner-container {
  width: 100%;
  max-width: 906px;
  margin: 0 auto;

  &.wide-inner-container {
    max-width: 990px;
  }
}

// used when there is full size background
.inner-page-container {
  padding: 0 150px;
  @media screen and (max-width: map-get($breakpoints,large)) {
    padding: 0 60px;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 0 40px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 0 25px;
  }
}
.hide-mobile {
  @media screen and (max-width: map-get($breakpoints,small)) {
    display: none !important;
  }
}

.center {
  margin: 0 auto;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.margined {
  margin: 24px 0;
}
.quote {
  font-style: italic;
}
.pointer {
  cursor: pointer !important;
}
//use for component
.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.start {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    & button:nth-child(1):not(:last-child) {
      margin-right: 10px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }
  }
  &.centered {
    justify-content: center;
    align-items: center;
  }
  &.left {
    justify-content: flex-start;
    @media screen and (max-width: map-get($breakpoints,medium)) and (min-width: map-get($breakpoints,small)) {
      &.hero {
        flex-direction: row;
      }
    }
  }
  &.right {
    justify-content: flex-end;
    & button:nth-child(1):not(:last-child) {
      margin-right: 10px;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin: 0 0 10px 0;
      }
    }
  }
  &.full-width {
    width: 100%;
  }
  &.tabs-group {
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: row;
      .btn {
        max-width: 100px;
      }
    }
  }

  &.two {
    .btn {
      width: 50%;
      &:not(:last-child) {
        margin-right: 16px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        &:last-child {
          margin-top: 0px;
        }
      }
    }
  }

  &.side-by-side {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .btn {
      width: 50%;
      &:not(:last-child) {
        margin-right: 16px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: map-get($breakpoints, medium)) {
    flex-direction: column;
    &.two {
      .btn {
        width: 100%;
        &:nth-child(1),
        &:not(.hero) {
          margin-bottom: 5px;
        }
        &:last-child {
          margin-top: 0px;
        }
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    &.left,
    &.right {
      justify-content: center;
      align-items: center;
    }
    &.two {
      .btn {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  &.row-btn-group {
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: row;
      justify-content: space-between;

      .btn {
        min-width: 48%;
      }
    }
  }
}
// NOTE: button is stand alone. Will be updated later with global button update
.btn {
  border: none;
  cursor: pointer;
  font-family: 'CodecColdBold' !important;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 16px 0 8px 0;

  .loading {
    border: 3px solid map-get(map-get($paletteNew, gray), white);
    border-top: 3px solid transparent;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin: 0 auto;
    animation: spin 0.6s linear infinite;
  }

  &.primary {
    background: map-get(map-get($paletteNew, primary), connectedCobalt);
    color: map-get(map-get($paletteNew, gray), white);
    &:hover:not(:disabled) {
      box-shadow: 0px 6px 20px -1px rgba(20, 35, 90, 0.25);
    }
    &:focus {
      box-shadow: 0px 6px 20px -1px rgba(20, 35, 90, 0.25);
      background: #4DA9FF ;
    }
    &:disabled {
      cursor: default;
      background: map-get(map-get($paletteNew, gray), gray2);
    }
    &.loading-mode:disabled {
      background: map-get(map-get($paletteNew, primary), connectedCobalt);
    }
  }
  &.tertiary {
    background: map-get(map-get($paletteNew, gray), white);
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
    &:hover:not(:disabled),
    &:focus {
      background: map-get(map-get($paletteNew, gray), gray1);
    }
    &:disabled {
      cursor: default;
      color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
  &.secondary {
    box-shadow: 0px 0px 0px 2px map-get(map-get($paletteNew, secondary), royal)
      inset;
    background-color: map-get(map-get($palette, primary), 3);
    color: map-get(map-get($paletteNew, secondary), royal);
    &:hover:not(:disabled) {
      background: map-get(map-get($paletteNew, secondary), royal);
      color: map-get(map-get($palette, primary), 3);
      box-shadow: none;
    }
    &:disabled {
      cursor: default;
      box-shadow: 0px 0px 0px 2px map-get(map-get($paletteNew, gray), gray3)
        inset;
      color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
  &.primary-alt {
    background: map-get(map-get($paletteNew, secondary), citron);
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
    &:hover {
      box-shadow: 0px 6px 20px -1px rgba(20, 35, 90, 0.25);
    }
    &:focus {
      box-shadow: 0px 6px 20px -1px rgba(20, 35, 90, 0.25);
      background: #DFFBCB ;
      color: map-get(map-get($paletteNew, primary), accessibleIndigo);
    }
    &:disabled {
      cursor: default;
      background: map-get(map-get($paletteNew, gray), gray2);
    }
  }
  &.sm {
    font-size: 16px;
    padding: 0px 30px;
    border-radius: 20px;
    height: 40px;
    min-width: 120px;
  }
  &.lg {
    font-size:18px;
    padding: 0px 54px;
    border-radius: 30px;
    max-width: 408px;
    height: 50px;
    min-width: 96px;
  }
  &.change-pwd-btn {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &.full-width {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  &.wide-btn {
    max-width: 438px;
    min-width: 438px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-width: 100%;
    }
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    &.lg {
      min-width: 0;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin: 16px 0 8px 0;
    &.lg {
      padding: 0px 20px;
      min-width: 0;
      margin: 5px 0;
      width: 100%;
      max-width: 100%;
    }
    &.sm {
      margin: 8px 0;
    }
  }
  
  &:focus {
    outline: none;
  }
  &.right {
    float: right;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Global Title and Header

.page-sub-title {
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -8px;
    margin-bottom: 16px;
  }
}
.page-actions {
  display: flex;
}
// TODO
h1 {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 16px 0;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    align-items: center;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 0px;
    align-items: center;
    justify-content: flex-start;
    text-align: inherit;
  }
  &.subinfo {
    align-items: flex-end;
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      align-items: flex-start;
      & > span {
        margin-top: 8px;
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
}
// NOTE: some text styling for spacing and color
.left-aligned {
  text-align: left;
}
.empty-state {
  color: map-get(map-get($paletteNew, gray), gray3);
}

.dark {
  color: map-get(map-get($paletteNew, gray), gray5);
}
.space-below-sm {
  padding-bottom: 16px;
}
.space-above-sm {
  padding-top: 16px;
}
.space-above-md {
  padding-top: 24px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding-top: 12px;
  }
}
.space-above-lg {
  padding-top: 32px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding-top: 12px;
  }
}
.centered {
  @media screen and (max-width: map-get($breakpoints, small)) {
    text-align: center;
  }
}
.mobile-centered {
  @media screen and (max-width: map-get($breakpoints, small)) {
    text-align: center;
  }
}

// TODO: this needs to be defined becasue semantic UI
b {
  font-weight: $semi-bold;
}
strong {
  font-weight: $bold;
}

.headline {
  border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);
  padding-bottom: 8px;
}

// TODO: will clean up when we do global input update
select {
  border: 1px solid map-get(map-get($paletteNew, gray), gray3);
  padding: 0 16px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  font-weight: $semi-bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: map-get(map-get($paletteNew, gray), gray4);
  background: map-get(map-get($palette, primary), 3);
  cursor: pointer;
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6093 -6.18684e-08L13 1.41538L7.19535 7.32308L6.5 8L5.80465 7.32308L-6.07893e-08 1.41538L1.3907 -5.16467e-07L6.5 5.2L11.6093 -6.18684e-08Z' fill='%234E5156'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right 16px top 50%;
  padding-right: 36px;
  &:hover {
    outline: none;
    border: 1px solid map-get(map-get($paletteNew, secondary), royal);
  }
  &:active,
  &:focus {
    outline: none;
    border-color: map-get(map-get($paletteNew, secondary), royal);
  }
  &:disabled {
    color: map-get(map-get($paletteNew, gray), gray3);
    background-color: map-get(map-get($paletteNew, gray), gray1);
    opacity: 1;
    cursor: default;
  }
  &.error {
    border: 1px solid map-get(map-get($paletteNew, action), errorRed);
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px
      rgba(map-get(map-get($paletteNew, gray), gray5), 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-webkit-autofill:disabled {
    -webkit-text-fill-color: map-get(map-get($paletteNew, gray), gray3);
    box-shadow: 0 0 0px 1000px map-get(map-get($paletteNew, gray), gray1) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.PhoneInput {
  .PhoneInputInput {
    padding-left: 75px !important;
    margin-left: -75px;
  }

  .PhoneInputCountry {
    border-right: 1px solid map-get(map-get($paletteNew, gray), gray3);
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6093 -6.18684e-08L13 1.41538L7.19535 7.32308L6.5 8L5.80465 7.32308L-6.07893e-08 1.41538L1.3907 -5.16467e-07L6.5 5.2L11.6093 -6.18684e-08Z' fill='%234E5156'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 9px;
    width: 70px;
    padding-left: 20px;
  }
  .PhoneInputCountryIcon {
    width: 20px;
    height: auto;
  }
  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
  .PhoneInputCountrySelectArrow {
    display: none;
  }

  .PhoneInputCountrySelect:disabled {
    opacity: 0;
  }
}

.container {
  width: 100%;
}

// TODO: modal broken

.dimmer-2 {
  position: fixed;
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  vertical-align: middle;
  z-index: 10003;
  padding: 2em;
  background: hsla(0, 0%, 100%, 0.5);
  -webkit-backdrop-filter: blur(2px);
  // background-color: red;
  backdrop-filter: blur(2px);
  line-height: 1;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  will-change: opacity;
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 2em 0;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    &.dimmer-no-padding {
      padding: 0;
    }
  }
}

.modal {
  max-width: 800px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 24px 48px -18px rgba(map-get(map-get($palette, secondary), 6), 0.2);
  background-color: map-get(map-get($palette, primary), 3);
  &.primary > .title-group {
    background: map-get(map-get($paletteNew, secondary), royal);
    color: map-get(map-get($palette, primary), 3);
  }
  &.error > .title-group {
    background: map-get(map-get($paletteNew, secondary), royal);
    color: map-get(map-get($palette, primary), 3);
  }
  .title-group {
    position: absolute;
    top: -48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    min-height: 48px;
    max-height: 48px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 1px -1px 10px rgba(map-get(map-get($palette, primary), 3), 0.5);
    @media screen and (max-width: map-get($breakpoints, small)) {
      left: 0;
    }
    .close-icon {
      position: absolute;
      right: 0;
      margin: 0 16px 0 0;
    }
  }
  &.sm {
    width: 500px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 350px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 90vw;
    }
  }
  &.md {
    width: 50vw;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 75vw;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 90vw;
    }
  }
  &.lg {
    min-width: 80vw;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 60vh;
      max-width: 90vw;
      min-width: 80vw;
    }
  }
  .modal-inner-content {
    box-shadow: 1px 1px 10px rgba(map-get(map-get($palette, primary), 3), 0.5);
    padding: 32px;
    background-color: map-get(map-get($palette, primary), 3);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 80vh;
    width: 100%;
    overflow-y: auto;

    .grid {
      margin-top: 0px;
    }
    // scrollbar-width: thin;
    &::-webkit-scrollbar-track {
      //full track
      background-color: transparent;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      //bar
      opacity: 0;
      cursor: pointer;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      height: 100%;
      padding: 24px;
    }
  }
}

.success-modal {
  .content {
    text-align: center;
    margin-bottom: 16px;
  }
  .text-right {
    text-align: left;
  }
  .btn {
    min-width: 340px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-width: 100%;
    }
  }
}
.confirm-modal {
  .content {
    text-align: center;
  }
  .btn-group {
    width: 70%;
    margin: 16px auto;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
  }
}

.kycStatus {
  display: inline;
  margin: 0 5px;

  &.red {
    color: map-get(map-get($palette, status), 1);
  }
  &.teal {
    color: map-get(map-get($paletteNew, secondary), royal);
  }
}

.pending-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem;
}

.pending-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

svg path,
svg rect {
  fill: map-get(map-get($paletteNew, secondary), royal);
}

// TODO: semantic to clean up
.ui.negative.tiny.message {
  padding: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ui.pointing.label {
  font-size: 16px !important;
  padding: 0.5rem 1rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.agreements {
  margin: 2rem 0;
  padding-right: 1rem;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    padding-right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .agreement {
    color: map-get(map-get($paletteNew, gray), gray5);
    background-color: rgba(map-get(map-get($paletteNew, gray), gray1), 0.4);
    padding: 0.8rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 350px;
    max-width: 100%;
    border-radius: 10px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 100%;
    }
    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
    }
    img {
      margin-left: 1rem;
    }
  }
}

.upload-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .webcam {
    cursor: pointer;
    height: 50px;
    width: 9%;
    border-radius: 8px;
    padding: 5px 8px;
    color: map-get(map-get($paletteNew, gray), gray3);
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 12%;
      border: none;
    }
    .cam-img {
      height: 80%;
    }
  }
}

// image loaderStyle

.loader-component {
  width: 100%;
  height: 100%;
  margin: 0px;
  text-align: center;
  padding: 5px;
  .circle-spinner {
    text-align: center;
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0 auto;
  }

  .double-bounce1 {
    background-color: rgba(
      map-get(map-get($paletteNew, secondary), royal),
      0.9
    );
  }
  .double-bounce2 {
    background-color: rgba(
      map-get(map-get($paletteNew, secondary), royal),
      0.2
    );
  }
  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 1.5s infinite ease-in-out;
    animation: sk-bounce 1.5s infinite ease-in-out;
  }

  .double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }

  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      opacity: 1;
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      opacity: 1;
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      opacity: 1;
    }
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}

.input {
  &.error {
    display: flex;
    text-align: left;
    padding: 0.3em 0.5em;
  }
}
// NOTE: To clean up when we refactor input global components
// input component input group
$err-red: map-get(map-get($paletteNew, action), errorRed);

.input-group {
  display: flex;
  flex-direction: column;
  &:not(.confirm-checkbox):not(.linqto-bucks) {
    margin-bottom: 16px;
  }
  label {
    color: map-get(map-get($paletteNew, gray), gray5);
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    &.label-footer {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
    .optional {
      color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
  input:not([type='checkbox']):not([type='radio']),
  textarea {
    border: 1px solid map-get(map-get($paletteNew, gray), gray3);
    box-sizing: border-box;
    padding: 0 16px;
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: $semi-bold;
    overflow: hidden;
    text-overflow: ellipsis;
    color: map-get(map-get($paletteNew, gray), gray5);
    resize: vertical;
    -webkit-appearance: none;
    &:hover {
      border-color: map-get(map-get($paletteNew, gray), gray3);
    }
    &:focus,
    &:active {
      outline: none;
      border-color: map-get(map-get($paletteNew, secondary), royal);
    }
    &:disabled {
      color: map-get(map-get($paletteNew, gray), gray3);
      background-color: map-get(map-get($paletteNew, gray), gray1);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      background-color: none;
      -webkit-box-shadow: 0 0 0px 1000px map-get(map-get($palette, primary), 3)
        inset;
      box-shadow: 0 0 0px 1000px map-get(map-get($palette, primary), 3) inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    &:-webkit-autofill:disabled {
      -webkit-text-fill-color: map-get(map-get($paletteNew, gray), gray3);
      box-shadow: 0 0 0px 1000px map-get(map-get($paletteNew, gray), gray1)
        inset;
      transition: background-color 5000s ease-in-out 0s;
      border-color: map-get(map-get($paletteNew, gray), gray1);
    }
    &::placeholder {
      color: map-get(map-get($paletteNew, gray), gray3);
    }
    &::selection {
      background-color: rgba(map-get(map-get($paletteNew, gray), gray4), 0.4);
      color: rgba(map-get(map-get($paletteNew, gray), gray5), 0.87);
    }
    &.text-end {
      width: 100%;
      text-align: end;
    }
  }
  &.error:not(.checkbox) {
    margin-bottom: 0.2rem;
    input,
    textarea,
    select {
      border: 1px solid $err-red;
      &:hover {
        border: 1px solid $err-red;
      }
      &:focus {
        border: 1px solid $err-red;
      }
    }
    .err-msg {
      color: $err-red;
      text-align: left;
    }
  }
  &.copy-input-group {
    position: relative;
    input:not([type='checkbox']):not([type='radio']):disabled {
      color: map-get(map-get($paletteNew, gray), gray5);
      background-color: map-get(map-get($palette, primary), 3);
    }
    .btn {
      position: absolute;
      right: 0;
      min-width: 88px;
      height: 47px;
      bottom: 0px;
      top: 9px;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        top: 16px;
      }
    }

    .share-text {
      position: absolute;
      top: 30px;
      left: 20px;
    }

    .share-link {
      position: absolute;
      font-size: 14px;
      color: map-get(map-get($paletteNew, gray), gray4);
      bottom: -20px;
    }

    .share-text[data-copied='1'] {
      opacity: 0;
      animation-name: ghosting;
      animation-duration: 0.8s;
      bottom: -20px;
    }
  }
  textarea {
    width: 100%;
    height: 200px;
    padding-top: 10px;
  }
  &.checkbox {
    margin-top: 1rem;
    .checkbox-label {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0.2rem 0;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin: 0.25rem 0 0 0;
      }
      &__text {
        margin-left: 25px;
      }
    }
    label {
      input {
        opacity: 0;
      }
    }
    &.error {
      .checkbox-label {
        &__text,
        &__text span,
        &__text span a {
          color: map-get(map-get($paletteNew, action), errorRed);
        }
        .checkmark {
          box-shadow: inset 0 0 0 1.5px $err-red;
          border: 1.5px solid rgba($err-red, 0.1);
        }
      }
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute !important;
      left: 0;
      top: 2px;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      border: 1px solid map-get(map-get($paletteNew, gray), gray3);
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: map-get(map-get($paletteNew, gray), gray1);
      cursor: pointer;
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: map-get(map-get($palette, primary), 3);
      border: 1px solid map-get(map-get($paletteNew, gray), gray3);
    }
    input:disabled ~ .checkmark {
      background-color: map-get(map-get($paletteNew, gray), gray3);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 3px;
      width: 7px;
      height: 12px;
      border: solid map-get(map-get($paletteNew, secondary), royal);
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .children {
      margin-left: 1rem;
    }

    &.default-check {
      pointer-events: none;
    }
    &.marketing-checkbox {
      margin-top: 8px;

      .checkmark {
        top: 5px;
      }

      .checkbox-label__text {
        color: map-get(map-get($paletteNew, gray), gray4);
      }
    }
  }

  &.country_dropdown {
    margin-top: 0.5rem;
    margin-left: 1.5rem;
  }
  .password-container {
    display: flex;
    align-items: center;

    .password-toggle {
      margin-left: -40px;
      cursor: pointer;
      width: 25px;
      height: 25px;
    }

    input {
      width: 100%;
    }
  }
  &.number-format {
    input {
      text-align: right;
    }
  }
}

.radio-group {
  .radio-option {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    input[type='radio'] {
      cursor: pointer;
      -webkit-appearance: none;
      appearance: none;
      padding: 0;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      display: inline-block;
      vertical-align: middle;
      background-origin: border-box;
      -webkit-user-select: none;
      user-select: none;
      flex-shrink: 0;
      height: 1rem;
      width: 1rem;
      margin-right: 10px;
      border: 1px solid map-get(map-get($paletteNew, gray), gray4);
      background-color: map-get(map-get($palette, primary), 3);
      border-radius: 10px;
      color: map-get(map-get($paletteNew, secondary), royal);
      @media screen and (max-width: map-get($breakpoints,medium)) {
        font-size: 18px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        font-size: 16px;
      }
    }
    input[type='radio']:checked {
      border-color: transparent;
      background-color: currentColor;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
    }
    input[type='radio']:disabled:checked {
      background-color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
}

.radio-group.radio-input-group {
  margin-top: 24px;
  .radio-option {
    align-items: center;
    label {
      color: map-get(map-get($paletteNew, gray), gray4);
      margin-left: 0px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        align-items: flex-start;
      }
    }
    input[type='radio'] {
      margin-top: 0px;
      margin-right: 16px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-top: 8px;
      }
    }
  }
}

.form-header {
  display: flex;
  flex-direction: row;
  font-weight: $semi-bold;
  margin: 16px 0;
  .checkbox {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.ui.grid {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// NOTE: this is for GLOBAL contact support copy + link
.contact-support-container {
  display: flex;
  color: map-get(map-get($paletteNew, gray), gray4);
  span {
    color: map-get(map-get($paletteNew, secondary), royal);
    margin-left: 5px;
    cursor: pointer;
  }
  &.right-aligned {
    justify-content: flex-end;
  }
  &.left-aligned {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: map-get($breakpoints,small)) {
  .ui.stackable.grid > .column:not(.row),
  .ui.stackable.grid > .wide.column {
    padding: 0 !important;
  }
}

.render-location-dropdown {
  width: 100%;
  &.error {
    border: 1px solid $err-red;
  }
}

.render-birth-date-dropdown {
  height: 100%;
  width: 100%;
  &.error {
    border: 1px solid $err-red;
  }
}

.full-width {
  width: 100%;
}

.signup-error .modal-inner-content {
  min-height: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}

.ui.grid > .column.column-padding:not(.row),
.ui.grid > .row > .column.column-padding {
  padding-right: 0;
  padding-left: 0;
}

@media screen and (max-width: map-get($breakpoints, medium)) {
  .ui.grid > .eight.wide.column.tablet_sixteen {
    width: 100% !important;
  }

  .ui.grid > .four.wide.column.tablet_eight,
  .ui.grid > .row > .four.wide.column.tablet_eight,
  .ui.grid > .four.eight.column.tablet_eight,
  .ui.grid > .row > .eight.wide.column.tablet_eight {
    width: 50% !important;
  }
  .ui.grid > .row > .three.wide.column {
    width: 25% !important;
  }
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .ui.grid > .four.wide.column.tablet_eight,
  .ui.grid > .six.wide.column.tablet_eight,
  .ui.grid > .eight.wide.column.tablet_eight {
    width: 100% !important;
  }

  .error-container {
    width: 100%;
    margin-left: 0;
  }
  .error.message {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .box .box-content {
    padding: 0px;
  }
  .box .box-title.top-border {
    padding-top: 15px;
  }

  .box .box-title {
    color: rgba(map-get(map-get($paletteNew, gray), gray5), 0.87);
  }
}
// NOTE: global form success
.contact-success-container {
  display: flex;
  flex-direction: column;
  .contact-success-content {
    display: flex;
    margin: 24px 0;
    align-items: center;
    // TODO: need to be updated with new global button style
    & > a {
      font-weight: $semi-bold;
      font-size: 24px;
      line-height: 96%;
      color: map-get(map-get($paletteNew, secondary), royal);
      margin: 0 100px 0 0;
      cursor: pointer;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      & > a {
        margin: 0 50px 0 0;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column-reverse;
      justify-content: center;
      text-align: center;
      // TODO: need to be updated with new global button style
      & > a {
        margin: 10px 0;
        font-size: 18px;
      }
    }
  }
}

.shrink-container {
  min-height: 30vh;
}

.d-flex-col-50 {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: map-get($breakpoints, small)) {
    width: 100%;
  }
}

.small-space {
  height: 16px;
}

.medium-space {
  height: 32px;
}

// NOTE: this is a global inline-text-link we should start using
.inline-text-link {
  font-weight: $semi-bold;
  color: map-get(map-get($paletteNew, secondary), royal);
  cursor: pointer;
  display: inline-block;
  min-height: 48px;
  min-width: 48px;
  &.gray {
    color: map-get(map-get($paletteNew, gray), gray4);
    &:hover {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
  }
  &.underlined {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.text-decoration-none {
  text-decoration: none;
}

// NOTE: this is a global link style
.link {
  font-size: 18px;
  font-weight: $semi-bold;
  line-height: 48px;
  min-height: 48px;
  display: inline-block;
  &.primary {
    color: map-get(map-get($paletteNew, secondary), royal);
  }
  &:hover {
    color: map-get(map-get($paletteNew, gray), gray3);
  }
  &.left-padded {
    padding-left: 8px;
  }
  &.gray {
    color: map-get(map-get($paletteNew, gray), gray4);
    &:hover {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
    cursor: default;
  }
  &.underlined {
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    &.modal-support {
      display: inline-block;
      width: auto;
    }
  }
}

.tablet-content,
.mobile-content {
  display: none !important;
}
.desktop-content {
  display: flex;
}
@media screen and (max-width: map-get($breakpoints, small)) {
  .tablet-content,
  .desktop-content {
    display: none;
  }
  .mobile-content {
    display: flex !important;
  }
}

@media screen and (max-width: map-get($breakpoints, medium)) {
  .mobile-content,
  .desktop-content {
    display: none;
  }
  .tablet-content {
    display: flex;
  }
}
.notification-banner {
  height: 48px;
  width: 100%;
  color: map-get(map-get($palette, primary), 3);
  background: map-get(map-get($paletteNew, secondary), royal);
  position: absolute;
  display: flex;
  .inner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 0 16px;
    }
  }

  .notification-banner-link {
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
  }
  &.notification-banner-fixed {
    position: relative;
    z-index: 997;
  }
  &.notification-banner-down {
    transform: translateY(-50px);
    animation: slideOutDown 1s 80ms;
    animation-fill-mode: forwards;
  }
  &.notification-banner-up {
    transform: translateY(0px);
    animation: slideOutUp 1s 80ms;
    animation-fill-mode: forwards;
  }
}
// TODO: legacy and not use in code but still need to confirm with product if needed to keep
// .team-apollo-banner {
//   height: 48px;
//   width: 100%;
//   color: map-get(map-get($palette, primary), 3);
//   background-color: map-get(map-get($paletteNew, gray), gray1);
//   display: flex;
//   align-items: center;
//   font-size: 18px;
//   padding: 0 42px;
//   @media screen and (max-width: map-get($breakpoints, medium)) {
//     font-size: 18px;
//     padding: 0 24px;
//   }
//   @media screen and (max-width: map-get($breakpoints, small)) {
//     font-size: 16px;
//     padding: 0 8px;
//   }

//   &_text {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     color: map-get(map-get($paletteNew, gray), gray4);
//     font-size: 16px;
//     font-weight: $regular;
//     cursor: pointer;
//   }

//   &_ta-icon {
//     margin-right: 22px;
//   }

//   &_close-icon {
//     cursor: pointer;
//     height: 44px;
//     width: 44px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }

//   &_ta-copy {
//     text-decoration: underline;
//   }

//   &.notification-banner-down {
//     transform: translateY(-50px);
//     animation: slideOutDown 1s 80ms;
//     animation-fill-mode: forwards;
//   }

//   &.notification-banner-up {
//     transform: translateY(0px);
//     animation: slideOutUp 1s 80ms;
//     animation-fill-mode: forwards;
//   }
// }

@keyframes slideOutDown {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-50px);
  }
}

.uphold-container {
  padding: 0px 24px 24px;
}
.header-copy {
  margin-bottom: 16px;
}

.document-section {
  margin-bottom: 40px;
  .margined {
    margin: 8px 0;
  }
}

.documents-list {
  margin-bottom: 32px;
  .upload-container-new {
    margin-bottom: 16px;
  }
}
.upload-container-new {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .error-type {
    color: map-get(map-get($paletteNew, gray), gray4);
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 16px;
  }
  &.full-width {
    .dropzone-new {
      width: 100%;
    }
  }
  &.noBottomMargin {
    margin-bottom: 8px;
  }
}

.dropzone-new {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid map-get(map-get($paletteNew, gray), gray3);
  width: 408px;
  border-radius: 8px;
  background-color: map-get(map-get($palette, primary), 3);
  height: 48px;
  padding-left: 16px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    width: 100%;
  }
  &:hover,
  &.dragging {
    border: 1px solid map-get(map-get($paletteNew, secondary), royal);
  }
  &.disabled {
    background-color: map-get(map-get($paletteNew, gray), gray1);
    .browse-button {
      cursor: default;
    }
  }
  .browse-button {
    padding: 8px 16px;
    height: 100%;
    background-color: map-get(map-get($paletteNew, gray), gray1);
    border: none;
    color: map-get(map-get($paletteNew, gray), gray4);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
  }
  .left-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    .doc-title {
      padding: 0 16px;
      color: map-get(map-get($paletteNew, gray), gray4);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .upload-img {
      width: 20px;
    }
  }
  .delete-icon {
    padding: auto 0;
    cursor: pointer;
    margin-right: 16px;
    svg {
      height: 100%;
      padding-top: 4px;
      stroke: map-get(map-get($paletteNew, gray), gray3);
      &:hover {
        stroke: map-get(map-get($paletteNew, secondary), royal);
      }
    }
  }

  .upload-arrow {
    margin-right: 8px;
  }

  &.dropzone-loading {
    background-color: map-get(map-get($paletteNew, gray), gray1);
    .doc-title {
      padding-left: 0;
      color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
  &.dropzone-error {
    border: 1px solid map-get(map-get($paletteNew, action), errorRed);
    .doc-title {
      padding-left: 0;
    }
  }
  &.click-to-view {
    &:hover {
      cursor: pointer;
    }
  }
}

.upload-progress-bar {
  height: 2px;
  background-color: map-get(map-get($paletteNew, secondary), royal);
  margin-top: -2px;
}

hr {
  margin-top: 48px;
  margin-bottom: 48px;
}
label {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip-icon {
  height: 13px;
  width: 13px;
  margin-left: 8px;
  cursor: pointer;
}
.tooltip-routing-image {
  margin-top: 20px;
  height: 50px;
  max-width: 210px;
}
.tooltip-box {
  width: 256px;
  padding: 24px;
  z-index: 999;
  background-color: map-get(map-get($palette, primary), 3);
  // position tool tip
  position: fixed;
  & > .tooltip-icon {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.hidden {
  visibility: hidden;
}
.stackable-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  button:first-child {
    margin-top: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 16px;
    }
  }
  & > button {
    width: 100%;
    margin: 8px 0;
  }
}

.toggle-switch-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  &-label {
    margin-right: 8px;
  }
}

.toggle-switch {
  position: relative;
  height: 31px;
  width: 51px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  background-color: transparent;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    box-shadow:
      0px 3px 8px rgba(0, 0, 0, 0.15),
      0px 3px 1px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    margin: 0;
  }
  &-disabled {
    cursor: default;
    opacity: 0.8;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.2s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      color: map-get(map-get($palette, primary), 3);
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: map-get(map-get($paletteNew, secondary), royal);
      color: map-get(map-get($palette, primary), 3);
    }
  }
  &-disabled {
    background-color: map-get(map-get($paletteNew, gray), gray2);
    cursor: not-allowed;
    &:before {
      background-color: map-get(map-get($palette, secondary), 8);
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: map-get(map-get($paletteNew, gray), gray3);
    color: map-get(map-get($palette, primary), 3);
    text-align: right;
  }
  &-switch {
    display: block;
    height: 27px;
    width: 27px;
    margin: 3px;
    background: map-get(map-get($palette, primary), 3);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border: 0 solid map-get(map-get($paletteNew, gray), gray3);
    border-radius: 20px;
    transition: all 0.2s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: -1px;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: '';
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    transform: scale(0.8);
  }
}

.blue-link {
  cursor: pointer;
  color: map-get(map-get($paletteNew, primary), connectedCobalt);
}

.no-wrap {
  white-space: nowrap;
}

.carousel {
  display: flex;
  flex-direction: column-reverse;
}

.carousel-container {
  &.m-80 {
    margin-top: -40px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: -20px;
    }
  }
  &.m-16 {
    margin-top: 16px;
  }
  &.mb-24 {
    margin-bottom: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-bottom: 16px;
    }
  }
}

.carousel-item {
  width: 100%;
  max-width: 1200px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.carousel-img {
  height: 60px;
  width: 100%;
  border-radius: 8px;
}

.control-dots {
  margin: 12px 0 !important;
  position: initial !important;
  display: flex;
  align-items: center;
  justify-content: center;

  .pointer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .disabled {
    opacity: 0.1;
  }

  .dot {
    margin: 0 2px !important;
    box-shadow: 0px 0px 0px !important;
    background: rgba(137, 154, 156, 0.3) !important;
    &.selected {
      background: map-get(map-get($paletteNew, secondary), royal) !important;
    }
  }
}

.slider-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
}

.slider {
  margin: 0 22px;
}

.rc-slider.rc-slider-disabled {
  height: 5px;
  padding: 0px;
}

.slider-btn {
  height: 26px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.text-error {
  margin-top: 20px;
  span,
  a {
    color: map-get(map-get($paletteNew, action), errorRed);
  }
  a {
    text-decoration: underline;
  }
}

.relative {
  position: relative;
}

.secondary-button {
  display: flex;
  color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  margin: auto;
  text-align: center;
  margin-top: 25px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  &.border-imaged-element {
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #C3F890 0%, #00D2E6 100%) 0 0 100% 0;
  }
  & span {
    font-family: 'CodecColdRegular' !important;
  }
}

.gray3 {
  color: map-get(map-get($paletteNew, gray), gray3) !important;
}

.gray5 {
  color: map-get(map-get($paletteNew, gray), gray5) !important;
}

.accessibleIndigo {
  color: map-get(map-get($paletteNew, primary), accessibleIndigo) !important;
}

.italic {
  font-style: italic;
}

.text-align-end {
  text-align: right;
}
