.add-advisor-form {
  margin-top: 48px;
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: 32px;
  }
  .content {
    margin-bottom: 32px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-bottom: 24px;
    }
  }

  .btn-group {
    width: 80%;
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 100%;
      flex-direction: column;
      .btn:not(:last-child) {
        margin-right: 0px;
      }
    }
  }
  .input-group.checkbox .checkbox-label {
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-left: 0px;
    }
  }
  .input-group.checkbox .checkbox-label__text {
    margin-top: -8px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: -5px;
    }
  }
}
