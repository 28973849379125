.landing-container .promo-container {
  background: linear-gradient(
    298.35deg,
    map-get(map-get($paletteNew, secondary), royal) 23.14%,
    map-get(map-get($palette, secondary), 1) 128.82%
  );
  box-shadow:
    0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0px 0px 0px 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  height: 204px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 150px;

  @media screen and (max-width: map-get($breakpoints, medium)) {
    height: auto;
    padding: 0px 24px;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
  }

  a {
    cursor: pointer;
    width: 100%;
  }
  .promo-wrapper {
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: column;
    }
  }
  .promo-image {
    display: flex;
    flex-direction: column;
    min-width: 220px;
    align-items: flex-end;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      align-items: flex-start;
    }
    span {
      color: map-get(map-get($palette, primary), 3);
      text-decoration: underline;
      display: block;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 16px;
      }
    }

    img {
      max-width: 133px;
      margin-bottom: 24px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        display: none;
      }
    }
  }
}
