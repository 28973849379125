.manual_kyc_wrapper {
  margin-top: 20px;

  .first-col.column {
    padding-left: 0px !important;
  }

  .verfication-fields {
    margin-top: 50px;
  }

  .show-zone {
    display: block;
    margin-bottom: 10px;
  }

  .hide-zone {
    display: none;
  }
  .error-container {
    padding-left: 0;
  }
}

.kyc-manual-modal .loader-wrapper {
  position: absolute;
  top: -10px;
  width: 100%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 535px !important;
}
.sub-header {
  margin: 0px;
  .optional {
    color: map-get(map-get($paletteNew, gray), gray3);
  }
}
.manual-upload {
  margin-bottom: 0px;
}

.max-length select {
  max-width: 200px;

  @media screen and (max-width: map-get($breakpoints, small)) {
    max-width: 100%;
  }
}
