.contact-container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  .info-group {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
      padding: 0;
      margin-top: 10px;
    }
    .info {
      width: 50%;
      padding: 20px 0;
      color: map-get(map-get($paletteNew, gray), gray5);
      .location {
        margin-bottom: 20px;
      }
      .sub-text {
        color: map-get(map-get($paletteNew, gray), gray4);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        padding: 0;
        .location {
          margin-bottom: 10px;
        }
      }
    }
  }
  .contact-form {
    padding: 10px 0;
    .ui.radio.checkbox > label {
      cursor: pointer;
    }
  }
}

.contact-header {
  span {
    color: map-get(map-get($paletteNew, gray), gray4);
  }
  .underline {
    text-decoration: underline;
    cursor: pointer;
  }
  flex-direction: row;
}
