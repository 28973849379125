.loader-wrapper {
  position: relative;
  height: 90vh;
  background: map-get(map-get($palette, primary), 3);

  .dimmer {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
    z-index: 1000;

    .loader {
      color: rgba(map-get(map-get($paletteNew, gray), gray5), 0.87);
      min-width: 3.4rem;
      padding-top: 4rem;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      z-index: 1000;
      transform: translateX(-50%) translateY(-50%);

      &:after,
      &:before {
        width: 3.4rem;
        height: 3.4rem;
        margin: 0 0 0 -1.7rem;
        position: absolute;
        content: '';
        top: 0;
        left: 50%;
      }

      &:before {
        border-radius: 500rem;
        border: 0.2em solid
          rgba(map-get(map-get($paletteNew, gray), gray5), 0.1);
      }

      &:after {
        animation: loader 0.6s linear;
        animation-iteration-count: infinite;
        border-radius: 500rem;
        border: 0.2em solid;
        border-color: map-get(map-get($paletteNew, gray), gray3) transparent
          transparent;
        box-shadow: 0 0 0 1px transparent;
      }
    }
  }
}
