.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;

  &.no-margin-bottom {
    margin-bottom: 0;
  }
  &.no-margin-top {
    margin-top: 0;
  }
  .row {
    position: relative;
    width: 100% !important;
    padding: 0;

    &.row-with-padding {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }
  .column,
  .row .column {
    position: relative;
    display: inline-block;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    vertical-align: top;
    &.four {
      width: 25%;
    }
    &.five {
      width: 20%;
    }
    &.six {
      width: 37.5%;
    }
    &.eight {
      width: 50%;
    }
    &.nine {
      width: 56.25%;
    }
    &.sixteen {
      width: 100%;
    }
    &.ten {
      width: 62.5%;
    }
    &.three {
      width: 33.33333333%;
    }
    &.two {
      width: 18.75%;
    }
    &.one {
      width: 6.25%;
    }
    @media screen and (max-width: map-get($breakpoints, medium)) {
      &.sixteen-tablet {
        width: 100%;
      }
      &.ten-tablet {
        width: 62.5%;
      }
      &.eight-tablet {
        width: 50%;
      }
      &.six-tablet {
        width: 37.5%;
      }
      &.five-tablet {
        width: 20%;
      }
      &.four-tablet {
        width: 25%;
      }
      &.three-tablet {
        width: 33.33333333%;
      }
      &.two-tablet {
        width: 18.75%;
      }
      &.one-tables {
        width: 6.25%;
      }
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      &.sixteen-mobile {
        width: 100%;
      }
      &.ten-mobile {
        width: 62.5%;
      }
      &.eight-mobile {
        width: 50%;
      }
      &.six-mobile {
        width: 37.5%;
      }
      &.five-mobile {
        width: 20%;
      }
      &.four-mobile {
        width: 25%;
      }
      &.three-mobile {
        width: 33.33333333%;
      }
      &.two-mobile {
        width: 18.75%;
      }
      &.one-mobile {
        width: 6.25%;
      }
    }
  }
}
