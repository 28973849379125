@import './status-bar';
@import './drop-area';
@import './request-modal';
@import './financial-advisor';
@import './us-upload-documents.scss';
@import './us-modal.scss';
@import './no-reg';
@import './eu-residents';
@import './crd-success-modal';
@import './_accredaition-status.scss';

.investor-status-container {
  min-height: 100vh;
  .info-container {
    margin: 30px 0 0 0;
  }
  .header-content {
    margin-top: 16px;
    & a {
      @media screen and (max-width: map-get($breakpoints,small)) {
        min-height: auto;
        line-height: 0;
      }
    }
  }
  .request-container-divider {
    margin-top: 3em;
    margin-bottom: 2em;
  }
  .request-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 0px;
    .request-info {
      ul {
        list-style-position: inside;
        margin-left: 16px;
      }
    }
    .top-margin {
      margin: 0.5em 0;
    }
  }
  .ui.grid {
    margin-top: 0;
    margin-bottom: 0;
  }
  .contact-support-container {
    margin-top: 40px;
  }
}

.country-dropdown-self-accreditation {
  margin: 10px 30px;
  select {
    width: 100%;
  }
}

.accreditation-types-modal {
  ul {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.selfAccreditation-dropdown {
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
  }
}

.accreditation-terms {
  margin-top: 32px;
  &.margin {
    margin-top: 70px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: 32px;
    }
  }
}

.uk-residents-section {
  margin: 24px 0 32px 0;
  color: map-get(map-get($paletteNew, gray), gray4);
  .header {
    margin: 24px 0 8px;
  }
  .radio-button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-bottom: 20px;
    }
  }

  .accreditation-terms {
    margin: 20px 0 40px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-top: 32px;
    }
  }
  

  .select {
    max-width: 430px;
    width: 100%;
    margin-bottom: 55px;
  }

  .checkbox-label {
    display: block;
    &__text {
      cursor: pointer;
      margin-left: 12px;
    }
    &__link {
      color: map-get(map-get($paletteNew, secondary), royal);
    }
  }
  .checkmark {
    margin-top: 1px;
  }

  .content-separator {
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray4);
    margin: 32px 0;
  }

  .btn-group {
    justify-content: end;

    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: 20px;
    }
  }
}

.modal-text {
  ul {
    padding: 0 20px;
  }
}
.self-acc-container {
  display: flex;
  justify-content: space-between;
  .content,
  .info-container {
    width: 100%;
    &:nth-child(2) {
      width: 80%;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
    & button {
      margin: auto;
    }
  }
}

.accredited-container {
  .btn.lg {
    min-width: 320px;
    margin-top: 40px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin-top: 32px;
    }
  }
}

.ui.divider {
  text-transform: none;
}

.investor-status-header-container {
  display: flex;
  flex-direction: column;
  & > .profile-progress-container {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: -40px;
      flex-direction: column-reverse;
    }
  }
}
