.selector {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  &:hover {
    background: map-get(map-get($paletteNew, gray), gray1);
    cursor: pointer;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 1rem;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 0.5rem;
  }
  img {
    height: 30px;
  }
}

.transfer-modal-field {
  padding: 0.5rem 0;
}

.items-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verification {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 0 0;
  margin: 0;
  &.review {
    margin-bottom: 1rem;
    padding: 0;
  }

  .subtitle {
    margin-top: 0.1rem;
    margin-bottom: 0.3rem;
    color: map-get(map-get($paletteNew, gray), gray3);
    &.red {
      color: map-get(map-get($paletteNew, action), errorRed);
    }
  }
}
