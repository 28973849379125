.ID-group {
  display: flex;
  flex-direction: column;

  .item {
    width: 50%;
    flex-grow: 1;
    padding: 24px 18px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow:
      0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0px 0px 0px 1px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);

    .item-text {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
    .item-img {
      width: 46px;
      height: 46px;
      background: map-get(map-get($paletteNew, gray), gray1);
      border-radius: 50%;
      margin-right: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.item-with-desc {
      flex-direction: column;
      align-items: start;

      .item-row {
        flex-direction: row;
        display: flex;
        align-items: center;
      }
      .item-description {
        padding-top: 24px;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    flex-direction: column;
    .item {
      width: 100%;
    }
  }
}
#camera-feedback {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
}

.selfie-container {
  #inputVideo {
    height: 100%;
  }
  #overlay {
    height: 100% !important;
    width: 100% !important;
  }
  .message.count {
    top: 55%;
  }
  .display-img {
    height: 100%;
  }
  .message {
    width: 300px;
  }
}

.webcam {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: map-get(map-get($paletteNew, gray), gray5);
  height: 100vh;
  width: 100%;
  overflow: hidden;
  .webcam-video {
    width: 80%;
    height: auto;
    background-color: map-get(map-get($paletteNew, gray), gray5);
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 100%;
      margin-top: 0;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
  }
}
.captured-img {
  // transform: scale(0.5);
  width: 100%;
  height: auto;
  object-fit: fill;
}
.cross-img {
  z-index: 100000;
  position: absolute;
  top: 54px;
  right: 16px;
}

.camera-btn-group {
  align-self: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  justify-content: flex-start;
  min-height: 230px;

  .btn-group {
    margin: 0px 24px;
    &.two {
      .btn:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
.capture-btn {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.selfie-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  z-index: 100000;
  margin-bottom: 8px;
  p {
    width: 50%;
    text-align: center;
    background-color: rgba(map-get(map-get($paletteNew, gray), gray4), 0.5);
    backdrop-filter: blur(27px);
    padding: 1rem;
    border-radius: 8px;
    color: map-get(map-get($palette, primary), 3);
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin: 0px 24px;
    }
  }
}

.identity-confirm {
  color: map-get(map-get($paletteNew, gray), gray5);
  margin: 24px 0 8px;

  .item {
    display: block;
    > div {
      display: block;
    }
  }
}

.restart-link {
  margin-top: 16px;
  color: map-get(map-get($paletteNew, secondary), royal);
  @media screen and (max-width: map-get($breakpoints,small)) {
    text-align: center;
    display: block;
  }
}

.auto-verification-error {
  div {
    margin-bottom: 24px;
  }
}

blinkid-in-browser::part(mb-button) {
  display: none;
}
blinkid-in-browser::part(mb-component) {
  border: 0px;
}
