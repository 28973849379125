.signin {
  margin: 87px 25vw;
  @media screen and (max-width: map-get($breakpoints,large)) {
    margin: 30px 13vw 50px;
  }
  @media screen and (max-width: map-get($breakpoints,medium)) {
    margin: 42px 5vw;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 30px 0px;
  }
  .signin-container {
    padding: 20px 0px;
    max-width: 436px;
    margin: 0 auto;
    .signin-header {
      margin-bottom: 16px;
      text-align: left;
    }
    .reset-content {
      cursor: pointer;
      text-decoration: underline;
      color: map-get(map-get($paletteNew, gray), gray4);
      display: flex;
      justify-content: flex-end;

      &:hover {
        color: map-get(map-get($paletteNew, gray), gray3);
      }
    }

    .signin-error {
      margin: 10px 0 0 0;
      color: map-get(map-get($paletteNew, action), errorRed);
    }

    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 20px 24px;
    }

    .grid .row {
      display: flex;
      align-items: center;
    }
    .input-group.checkbox.remember-me-checkbox {
      margin: 0px;

      .checkmark {
        top: 5px;
      }
      .checkbox-label__text {
        color: map-get(map-get($paletteNew, gray), gray4);
      }
    }
  }
}

form.ui.form input {
  background-color: map-get(map-get($palette, primary), 3) !important;
  border: none !important;
  &:focus {
    border-color: map-get(map-get($paletteNew, secondary), royal) !important;
  }
}

.ui.form .field.field input:-webkit-autofill {
  background-color: map-get(map-get($palette, primary), 3) !important;
  &:focus {
    border-color: map-get(map-get($paletteNew, secondary), royal) !important;
    background-color: map-get(map-get($palette, primary), 3) !important;
  }
}

.reset-modal {
  .success-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .success-icon {
      height: 30px;
    }
    p {
      padding: 10px;
    }
  }
}

.reset-password-modal {
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 1rem 0 0 0;
  }
  .link {
    display: inline;
    cursor: pointer;
  }
}

.reset-password-container {
  margin: 30px 25vw 150px;
  min-height: 50vh;
  padding-bottom: 100px;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    margin: 30px 5vw 100px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 30px 10px;
  }
  form {
    display: flex;
    flex-direction: column;
    .modal-title {
      text-align: center;
    }
  }
  .reset-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    .reset-icon {
      height: 100px;
    }
  }
}

.mfa-group {
  .item {
    padding: 1rem;
    border: 2px solid map-get(map-get($paletteNew, secondary), royal);
    border-radius: 20px;
    margin: 10px;
    cursor: pointer;
    &:hover {
      background-color: map-get(map-get($paletteNew, gray), gray1);
    }
  }
}
