.portfolio-container {
  .invest-tabs-container {
    .page-tabs {
      justify-content: flex-start;
      .tab {
        margin-right: 50px;
        padding-bottom: 8px;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          margin-right: 18px;
        }
      }
    }
  }
  .page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: map-get($breakpoints,small)) {
      align-items: flex-start;
      min-height: 48px;
    }
  }
}

.download-pdf {
  padding-top: 6px;
  margin-left: 12px;
  a {
    cursor: pointer;
  }
}
.accounts-container {
  display: flex;
  flex-direction: column;
  .portfolio {
    display: flex;
    flex-direction: column;
    .account-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 5px 0px;
      .add-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
}

.total-investment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: map-get($breakpoints,small)) {
    align-items: unset;
  }
  .investment-total-container {
    display: flex;
    flex-direction: column;

    .investment-total {
      color: map-get(map-get($paletteNew, secondary), royal);
      margin: 0;
      &_copy {
        color: map-get(map-get($paletteNew, gray), gray4);
      }
    }
    .super-script {
      color: map-get(map-get($paletteNew, secondary), royal);
      vertical-align: super;
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    flex-direction: column;
  }

  .investment-cta-container {
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin: 32px 0 24px 0;
    }
  }

}

.investment-no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  min-height: 40vh;
  margin-top: 40px;

  .inner-container {
    margin: 42px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    width: 100%;
  }

  .copy-container {
    .main {
      color: map-get(map-get($paletteNew, gray), gray3);
    }

    .secondary {
      color: map-get(map-get($paletteNew, gray), gray3);
    }
    & button {
      width: 100%;
      margin-top: 45px;
    }
  }

  @media screen and (max-width: map-get($breakpoints,small)) {
    min-height: 30vh;
  }
}

.investments-container {
  border-top: 0.5px solid map-get(map-get($paletteNew, gray), gray4);
  @media screen and (max-width: map-get($breakpoints, medium)) {
    .inner-container {
      width: 100%;
      padding: 0 24px;
    }
  }

  .current-investment-item-container {
    margin-top: 24px;
  }

  .current-investment-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    padding: 20px 16px;
    border: 1px solid map-get(map-get($paletteNew, gray), gray1);
    box-shadow:
      0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0px 0px 0px 1px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    border-radius: 8px;
    min-height: 61px;
    cursor: pointer;

    &_single {
      margin-bottom: 64px;
    }
    &_left {
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
        margin-right: 16px;
        height: 40px;
        width: 40px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          height: 32px;
          width: 32px;
        }
      }
      span {
        @media screen and (max-width: map-get($breakpoints, medium)) {
          overflow-wrap: anywhere;
        }
      }
    }
    &_right {
      display: flex;
      &.shares-total-price-container {
        display: flex;
        @media screen and (max-width: map-get($breakpoints, small)) {
          align-items: flex-end;
          flex-direction: column-reverse;
          margin-right: 24px;
        }
      }
      &.shares {
        margin-right: 32px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          white-space: nowrap;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 0;
        }
      }
      &.total-price {
        margin-right: 42px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          margin-right: 32px;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 0;
        }
      }
      &.status {
        text-transform: capitalize;
        margin-right: 52px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 24px;
        }
      }
    }
  }

  .current-investment-item-title {
    display: flex;
    justify-content: space-between;
    padding-right: 75px;
    margin-top: 32px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding-right: 65px;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding-right: 57px;
    }

    &_right {
      align-items: flex-end;
    }
  }
  .investment-cta {
    margin: 65px 0;

    button {
      margin: auto;
      width: 100%;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      margin: 48px 0;
    }
  }

  .past-holdings-container {
    margin: 48px 0;
  }

  .page-container {
    padding: 0;
    min-height: 80vh;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 0 24px;
    }
  }
}

.order-list-container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
  }
  .order-list-title {
    margin-top: 16px;
    img.company-image {
      border-radius: 50%;
      margin-right: 16px;
      width: 56px;
      height: 56px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .order-headers {
    text-transform: uppercase;
    text-align: right;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: none;
    }
    .date {
      min-width: 95px;
      text-align: left;
    }
    .product {
      min-width: 180px;
      text-align: center;
    }
    .series {
      min-width: 20px;
    }
    .price {
      min-width: 80px;
    }
    .sharePrice {
      min-width: 50px;
    }
    .status {
      min-width: 80px;
      text-align: center;
    }
  }

  .btn-group {
    .btn.lg {
      &:nth-child(1) {
        margin-right: 16px;
      }
      &:last-child {
        margin-right: 0px;
      }
      width: 200px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }
    }
    @media screen and (max-width: map-get($breakpoints, medium)) {
      flex-direction: row;
    }
  }
}
.order-list-summary-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.order-list-summary {
  width: 437px;
  border: 1px solid map-get(map-get($paletteNew, gray), gray1);
  box-shadow:
    0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0px 0px 0px 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  border-radius: 8px;
  padding: 8px 16px;
  margin-top: 16px;
  .order-list-summary-row {
    display: flex;
    justify-content: space-between;
    div {
      color: map-get(map-get($palette, primary), 4);
      margin: 8px;
      &.number {
        text-align: right;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
    margin: 16px 0px;
  }
}
.label {
  border-radius: 5px;
  max-width: 180px;
  display: inline-block;
  padding: 5px 10px;
  background-color: map-get(map-get($palette, primary), 3);
  color: map-get(map-get($palette, primary), 3);
  text-align: center;
  cursor: default;
  width: 90%;
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
  }
  &.completed {
    background-color: map-get(map-get($paletteNew, primary), freshMint);
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  }
  &.cancelled {
    background-color: rgba(map-get(map-get($paletteNew, data), red), 0.15);
    color: map-get(map-get($paletteNew, data), red);
  }
  &.active {
    background-color: rgba(
      map-get(map-get($paletteNew, primary), openOcean),
      0.15
    );
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  }
  &.pending {
    background-color: map-get(map-get($paletteNew, secondary), citron);
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  }
  &.closed {
    background-color: map-get(map-get($paletteNew, gray), gray2);
    color: map-get(map-get($paletteNew, gray), gray5);
  }
}

.history-grid-container {
  margin-top: 32px;
  &__columns {
    display: grid;
    grid-template-columns: 1.15fr 2fr 0.75fr 1fr 1.25fr 1.25fr 0.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: baseline;
    padding: 0 16px;

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: none;
    }
    &.no_company {
      grid-template-columns: 1.5fr 0.75fr repeat(3, 1fr) 0.5fr;
    }
    &.cash_history {
      grid-template-columns: 0.75fr 2fr 0.75fr;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1.15fr 2fr 0.75fr 1fr 1.25fr 1.15fr 0.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border: 1px solid map-get(map-get($paletteNew, gray), gray1);
    box-shadow:
      0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0px 0px 0px 1px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    border-radius: 8px;
    margin: 16px 0;
    padding: 0 16px;
    min-height: 61px;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.not-clickable {
      cursor: default;
    }

    &.cash_history {
      grid-template-columns: 0.75fr 2fr 0.75fr;
      padding: 16px;
      align-items: flex-start;
      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column;
        padding: 12px 16px;
      }
    }

    &.no_company {
      grid-template-columns: 1.5fr 0.75fr repeat(3, 1fr) 0.5fr;
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      min-height: 80px;
      h1 {
        margin: 0;
      }
      &__company-info {
        display: flex;
        flex-direction: column;
        text-align: right;
        justify-content: center;
        margin-right: 20px;
        :nth-child(1) {
          overflow-wrap: anywhere;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 16px;
        }
      }
      &__date-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
      }
    }
    &__full-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.current-investment-empty {
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 20px 16px;
  border: 1px solid map-get(map-get($paletteNew, gray), gray1);
  box-shadow:
    0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0px 0px 0px 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  border-radius: 8px;
  min-height: 61px;
}

.history-title {
  margin: 32px 0 24px;
}
// NOTE: need to keep the small text here
.flag {
  height: 20px;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  left: 0;
  right: 0;
  position: absolute;
  top: -8px;
  margin-left: auto;
  margin-right: auto;
  &.active {
    background: map-get(map-get($paletteNew, primary), openOcean);
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  }
  &.cancelled {
    background-color: rgba(map-get(map-get($paletteNew, data), red), 0.15);
    color: map-get(map-get($paletteNew, data), red);
  }
  &.pending {
    background-color: map-get(map-get($paletteNew, secondary), citron);
    color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  }
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
