.status-container {
  display: flex;
  flex-direction: row;
  .status-group {
    position: relative;
    .status {
    }
    .dots {
      height: 10px;
      width: 10px;
      background-color: map-get(map-get($paletteNew, gray), gray3);
      border-radius: 50%;
      display: inline-block;
      z-index: 100;
    }
  }
  .line {
    width: 100%;
    position: relative;
    top: 27px;
    left: 100px;
    z-index: 0;
  }
}

:root {
  --background-modal-color: map-get(map-get($palette, primary), 3);
  --body-color: map-get(map-get($palette, primary), 3);
  --color-timeline-default: map-get(map-get($palette, status), 4);
  --color-step-completed: map-get(map-get($palette, status), 2);
  --color-checkmark-completed: map-get(map-get($palette, primary), 3);
  --color-in-progress: map-get(map-get($palette, status), 3);
  --color-label-default: var(--color-timeline-default);
  --color-label-completed: var(--color-step-completed);
  --color-label-loading: var(--color-in-progress);
  --color-icon-completed: var(--color-step-completed);
  --color-icon-default: var(--color-timeline-default);
}
