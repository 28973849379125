.linqto-wallet-container {
  display: flex;
  flex-direction: column;

  .owner-name {
    margin-top: -5px;
    margin-bottom: 20px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: -5px;
      margin-bottom: 16px;
    }
  }
  .investments-container {
    border-top: 0px;
  }
  .linqto-wallet-transactions {
    margin-top: 48px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: 32px;
    }
  }

  .btn-group {
    align-items: center;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
      margin-top: 24px;
    }
    .btn {
      margin-left: 16px;
      min-width: 180px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        min-width: 100%;
        margin-left: 0;
      }
    }
  }

  .entity-item {
    padding-bottom: 16px;
    margin: 48px 0 16px;
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray4);
    color: map-get(map-get($paletteNew, gray), gray4);
  }
}

.linqto-wire {
  .add-funds-info {
    display: flex;
    flex-direction: column;

    div,
    span {
      &.add-funds-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        p {
          min-width: 180px;
          @media screen and (max-width: map-get($breakpoints,small)) {
            min-width: 50%;
          }
        }
      }

      &.add-funds-section {
        margin-top: 16px;
      }

      p {
        margin-bottom: 0px;
        &.add-funds-row-address {
          @media screen and (max-width: map-get($breakpoints,small)) {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  &.mfa-modal {
    .loader-wrapper {
      width: 100% !important;
    }
  }
}

.uphold-wallet-container {
  margin-top: 40px;

  div.info {
    color: map-get(map-get($paletteNew, gray), gray4);
  }

  .uphold-wallet-header {
    margin-bottom: 20px;
  }
  .uphold-wallet-not-connected {
    margin-top: 20px;
  }

  .link-account-container {
    text-align: center;
    margin-top: 24px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .uphold-wallet-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      align-items: center;
      display: flex;
    }
    &.uphold-wallet-row-header {
      img {
        margin-right: 16px;
        width: 40px;
        height: 40px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          width: 32px;
          height: 32px;
        }
      }
    }
    &.uphold-wallet-row-left {
      span {
        width: 100%;
        text-align: left;
        color: map-get(map-get($paletteNew, gray), gray4);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 60%;
      }
    }
  }
  .uphold-wallet-accounts {
    margin-top: 8px;
    padding-left: 40px;
    margin-left: 20px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-left: 0px;
    }
    .uphold-wallet-account-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray2);
      padding: 16px 0px;
      div {
        display: flex;
        align-items: center;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 30px;
      }
      &.cheveron-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid map-get(map-get($paletteNew, gray), gray2);
        border-bottom: none;
        padding: 0;
        .pointer {
          padding-top: 8px;
          padding-bottom: 8px;
          color: map-get(map-get($paletteNew, gray), gray4);
          img {
            margin-right: 0;
          }
        }
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
}
