.sell-offer-container {
  .sell-offer-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 78px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-bottom: 0px;
      flex-direction: column;
    }
    &.sell-order-header {
      margin-bottom: 24px;
    }
    &.buy-order-header {
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-bottom: 24px;
      }
    }

    h1 {
      margin-bottom: 0;
      @media screen and (max-width: map-get($breakpoints, small)) {
        min-width: 50%;
      }
    }

    .sell-offer-company-name {
      display: flex;
      align-items: center;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 20px;
      }
    }
    img {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid map-get(map-get($paletteNew, gray), gray1);
      margin-right: 10px;
    }
    &__copy {
      margin-top: 24px;
      cursor: pointer;
      &.link {
        color: map-get(map-get($paletteNew, secondary), royal);
        display: initial;
      }
    }
  }
  .sell-offer-section {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
    }
    &.wih-margin {
      margin-bottom: 42px;
    }
    .sell-offer-section-title {
      width: 30%;
      padding-top: 0px;
      display: flex;
      align-items: center;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 40%;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
        margin-bottom: 24px;
      }
      span {
        border: 3px solid map-get(map-get($paletteNew, secondary), royal);
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: map-get(map-get($paletteNew, secondary), royal);
        margin-right: 8px;
      }
    }
    .sell-offer-order {
      width: 60%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
      &.sell-offer-order-end {
        margin-left: auto;
      }
      .sell-offer-copy {
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-top: 20px;
        }
      }
      .invest-order-box {
        padding: 24px 16px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-bottom: 0px;
        }
      }
      .agreements {
        padding-right: 0rem;
        margin: 0px;
        .agreement {
          width: 100%;
        }
      }
    }
    &.flex-end {
      justify-content: flex-end;
    }
    &.sell-offer-footer {
      margin-top: 40px;
      .sell-offer-footer-copy {
        margin-bottom: 12px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 12px;
        .sell-offer-footer-copy {
          text-align: center;
          display: block;
          margin-bottom: 0px;
        }
      }
    }
    &.spaced {
      margin-top: 8px;
    }
    &.sell-offer-section-no-mobile-title {
      @media screen and (max-width: map-get($breakpoints, small)) {
        .sell-offer-section-title {
          display: none;
        }
      }
    }
  }

  .box.withdraw-box {
    padding: 0px 16px 16px 16px;

    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 0px;
      margin-bottom: 0 !important;
    }
  }

  .sell-offer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-group.checkbox .checkbox-label__text {
      padding: 0px;
      margin-top: -3px;
    }
    span {
      padding: 6px 0;

      &.dark {
        color: map-get(map-get($paletteNew, gray), gray5);
      }
      &.input {
        display: flex;
        align-items: center;
      }
      &.input-top {
        align-items: flex-start;
        @media screen and (max-width: map-get($breakpoints, small)) {
          span {
            margin-top: 4px;
          }
        }
      }
      &.red {
        color: map-get(map-get($paletteNew, data), red);
        .inline-text-link {
          color: map-get(map-get($paletteNew, data), red);
          text-decoration: underline;
        }
      }
      &.select-input {
        width: 100%;
        margin-bottom: 16px;
      }
      &.toggle-switch-inner {
        padding: 0px;
      }
      &.width-70 {
        @media screen and (max-width: map-get($breakpoints,small)) {
          width: 70%;
        }
      }
    }
    .linqto-bucks-wrapper {
      display: flex;
      span {
        padding: 0px;
      }
      .linqto-copy {
        display: flex;
        flex-direction: column;
      }
      .linqto-img {
        margin-right: 16px;
      }
    }
    select {
      width: 100%;
    }
    &.flex-end {
      justify-content: flex-end;
    }
    &.spaced {
      margin-top: 8px;
    }
    &.total-spaced {
      margin-top: 24px;
    }
    &.sell-offer-full-width {
      .input-group {
        width: 100%;
      }
    }
    &.sell-offer-row-top {
      align-items: flex-start;

      span.label-top {
        margin-top: 10px;
      }
    }
  }
  // TODO: this need to move out of sell Offer
  .linqto-bucks-row {
    margin: 20px 0;
    .linqto-bucks-terms {
      margin-top: 10px;
    }
  }
  // TODO: this need to move out of sell Offer
  .input-group {
    width: 100px;
    display: inline-flex;
    margin-bottom: 0px !important;

    &.confirm-checkbox {
      width: 100%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-bottom: 16px !important;
      }
    }
    input:not([type='checkbox']):not([type='radio']):not(.promo-input) {
      margin-left: 5px;
      text-align: right;
    }
  }

  .sell-offer-totals {
    padding: 0px 16px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 0px;
      margin-top: 16px;
    }
  }
  .sell-offer-cta {
    width: 50%;
    margin-top: 40px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      .btn-group {
        flex-direction: row;
        &.two .btn:not(:last-child) {
          margin: 0px;
          margin-right: 10px;
        }
      }
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
  }

  .sell-offer-page-container {
    max-width: 672px;
    margin: auto;
    display: flex;
    flex-direction: column;
    .customer-support-copy {
      color: map-get(map-get($paletteNew, gray), gray4);
      margin: 16px 0;
    }
    .btn-group {
      flex-direction: row;
      button {
        width: 180px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 100%;
        }
      }
    }
    &__sub-header {
      color: map-get(map-get($paletteNew, gray), gray5);
    }
  }
  .sell-offer-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 32px 16px;
    gap: 8px;
    background: map-get(map-get($palette, primary), 3);
    border: 1px solid map-get(map-get($paletteNew, gray), gray1);
    box-shadow:
      0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0px 0px 0px 1px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    border-radius: 8px;
    margin: 24px 0 16px 0;

    &__completed-sales-header {
      margin: 16px 0;
    }

    &.completed-sales {
      margin: 8px 0;
      padding: 16px;
    }

    &__bottom-container {
      margin-top: 32px;
      &.top {
        margin-top: 16px;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 24px;
      }
    }

    &__badge {
      margin: auto;
      padding: 8px;
      color: map-get(map-get($paletteNew, gray), gray5);
      border-radius: 8px;
      cursor: default;
      &.closed {
        background-color: map-get(map-get($paletteNew, gray), gray2);
        color: map-get(map-get($paletteNew, gray), gray5);
      }
      &.completed {
        background-color: map-get(map-get($paletteNew, primary), freshMint);
        color: map-get(map-get($paletteNew, primary), accessibleIndigo);
      }
      &.canceled,
      &.cancelled {
        background-color: rgba(map-get(map-get($paletteNew, data), red), 0.15);
        color: map-get(map-get($paletteNew, data), red);
      }
      &.active {
        background: map-get(map-get($paletteNew, primary), openOcean);
        color: map-get(map-get($paletteNew, primary), accessibleIndigo);
      }
    }
    &__copy {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__input {
        align-items: center;
        input {
          margin-top: -12px;
        }
      }
      &__copy {
        color: map-get(map-get($paletteNew, gray), gray5);
        &.bold {
          margin: 0;
          color: map-get(map-get($palette, secondary), 7);
        }
        &.error {
          color: rgba(map-get(map-get($paletteNew, action), errorRed), 0.8);
        }
        &.light {
          margin: 0;
          color: map-get(map-get($paletteNew, gray), gray3);
        }
      }
      &__cost-basis {
        &.copy {
          color: map-get(map-get($paletteNew, gray), gray4);
        }
        &.order-container {
          display: flex;
          flex-direction: column;
          margin: 4px 0;
        }
        &.order-copy {
          color: map-get(map-get($paletteNew, secondary), royal);
        }
        &.share-copy {
          color: map-get(map-get($paletteNew, gray), gray4);
        }
      }
      &.line-above {
        border-top: 1px solid map-get(map-get($paletteNew, gray), gray3);
        margin-top: 10px;
        padding-top: 10px;
      }
      &.m-4 {
        margin: 4px 0;
      }
      &.footer {
        margin-top: 16px;
      }
      &__right-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        & > img {
          margin-left: 10px;
        }
      }
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 50px;
    }
  }
  .sale-agreement-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 16px;
    gap: 8px;
    height: 62px;
    background: map-get(map-get($paletteNew, gray), gray1);
    border: 1px solid map-get(map-get($paletteNew, gray), gray1);
    box-shadow:
      0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0px 0px 0px 1px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    border-radius: 8px;
    &__left {
      display: flex;
      align-items: center;
    }
    &__copy {
      margin-left: 8px;
    }
  }
  .sell-terms-copy-container {
    .input-group {
      width: 100%;
      .checkbox-label {
        margin: 0;
      }
    }
    .checkbox-label__text {
      color: map-get(map-get($paletteNew, gray), gray5);
    }
    .checkmark {
      margin-top: 6px;
    }
  }
}
.sell-order-container {
  .input-group {
    width: auto;
    align-items: flex-end;

    input {
      max-width: 100px;
    }
  }
}
