.crd-modal-container {
  .container {
    .message {
      margin: 32px 0 8px 0;
      @media screen and (min-width: map-get($breakpoints, medium)) {
        margin: 16px 0 8px 0;
      }
    }
  }

  & button {
    width: 50%;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
    }
  }
}
