.unicorns-container {
  .page-container {
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding-top: 15px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding-top: 0px;
    }
  }
  .inner-container {
    position: relative;
  }

  .page-header {
    position: relative;
    text-align: left;
    color: map-get(map-get($palette, primary), 3);
    height: 400px;
    overflow: hidden;
    background: linear-gradient(
        to right,
        transparent,
        map-get(map-get($paletteNew, secondary), royal)
      ),
      linear-gradient(
        to bottom,
        map-get(map-get($paletteNew, secondary), royal),
        map-get(map-get($paletteNew, secondary), royal)
      );

    @media screen and (max-width: map-get($breakpoints, medium)) {
      height: 300px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
    .unicorn-backgeound {
      position: absolute;
      transform: translateX(-280px);
      opacity: 0.8;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        transform: translateX(-200px) scale(0.75);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        transform: translateX(-100px) scale(0.5);
      }
    }

    .inner-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    .page-header-title-container {
      width: 40%;

      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 60%;
        margin-left: 20px;
      }
    }
    .page-header-title {
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: 5px;
        margin-top: 25px;
      }
    }
    .powered-by {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: 10px;
        flex-wrap: wrap;
      }

      img {
        width: 120px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          width: 75px;
        }
      }
    }
    .page-header-subtitle {
      color: map-get(map-get($paletteNew, gray), white);
    }
    .page-header-fact-item {
      @media screen and (max-width: map-get($breakpoints,small)) {
        height: 30px;
      }
    }
    .unicorn-header-img {
      width: 408px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 300px;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 150px;
        margin-right: 20px;
      }
    }
  }
  .card-single-line {
    img {
      max-height: 50px;
    }
  }
  .card {
    width: 100%;
    height: 130px;
    border-radius: 8px;
    background-color: map-get(map-get($palette, primary), 3);
    padding: 10px;
    margin: 1rem 0;
    transition: 0.3s box-shadow ease;
    display: flex;
    overflow: hidden;
    box-shadow:
      0 0 10px 0px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
      inset 0 0 0 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 100%;
      height: 110px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      box-shadow: 0 0 10px 5px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
      height: 80px;
      margin: 14px 0;
    }
    &:hover {
      box-shadow:
        0 0 30px 0px rgba(map-get(map-get($paletteNew, gray), gray5), 0.1),
        inset 0 0 0 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
    }
    &:active {
      transform: scale(0.97);
    }
  }
  .card {
    cursor: pointer;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    color: map-get(map-get($paletteNew, gray), gray5);
    img {
      max-height: 50px;
      max-width: 175px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: map-get($breakpoints,medium)) {
      img {
        max-height: 40px;
        width: 80%;
      }
    }

    @media screen and (max-width: map-get($breakpoints,small)) {
      height: 66px;
      margin: 7px 0px;

      img {
        max-height: 42px;
        max-width: 80%;
      }
    }
  }

  .ui.grid > .row {
    @media screen and (max-width: map-get($breakpoints,medium)) {
      .column {
        width: 33.3% !important;
      }
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .column {
        width: 100% !important;

        &.column.two {
          width: 50% !important;
        }
        &.column.card-single-line.two {
          width: 100% !important;
        }
      }
    }
  }

  .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .loader-component {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .card-valuation {
    text-align: center;
    margin-bottom: 15px;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      margin-bottom: 5px;
    }
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    .card-single-line {
      .card {
        flex-direction: row;
        height: 60px;
        margin-bottom: 5px;
        img {
          max-height: 40px;
          margin-left: 15px;
          width: 35%;
        }

        .card-valuation {
          width: 50%;
          margin-bottom: 0px;
          text-align: right;
          margin-right: 15px;
        }
      }
    }
  }

  .loader-component {
    height: 50px;
  }

  .unicrons-filters {
    .ui.grid {
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    .unicrons-filters-search {
      position: relative;
      .input-group {
        margin-bottom: 0;
        margin-top: 5px;
      }
      input[type='text'] {
        padding: 8px 35px 8px 12px;
        width: 100%;
      }
      img {
        position: absolute;
        top: 15px;
        right: 12px;
        opacity: 0.7;
      }
    }
    .select-column {
      width: 22.22% !important;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        padding-right: 0px !important;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100% !important;
        margin-bottom: 5px;
        padding: 0px 16px !important;
      }
    }

    .select-input {
      width: 33.33% !important;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100% !important;
        padding: 0px 16px;
      }
    }
    select {
      width: 100%;
      margin-top: 5px;
    }
  }

  .empty-unicrons {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.unicorns-details-container {
  background-color: map-get(map-get($palette, primary), 3);
  clear: both;
  .inner-container {
    position: relative;
  }
  .page-header {
    background: map-get(map-get($paletteNew, gray), gray1);
    text-align: left;
    color: map-get(map-get($paletteNew, gray), gray5);
    align-items: flex-start;
    min-height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);
    margin-bottom: -158px;
    padding: 80px 0 100px 0;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 50px 0 80px 0;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      height: auto;
      margin-bottom: -124px;
      padding: 50px 0 80px 0;
    }
    .unicorn-backgeound {
      position: absolute;
      transform: translate(-386px, -36px);

      @media screen and (max-width: map-get($breakpoints, medium)) {
        transform: translate(-11%, -36px);
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: none;
      }
    }
  }
  .page-header-company-info {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }

    .page-header-company-logo {
      width: 55%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      margin-left: -36px;
      margin-top: -36px;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-left: 0px;
        width: 50%;
        flex-direction: column;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        flex-direction: column;
        margin-left: 0px;
        margin-top: 10px;
      }

      .page-header-company-logo-wrapper {
        min-width: 267px;
        height: 267px;
        border: 1px solid map-get(map-get($paletteNew, gray), gray1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .page-header-company-logo-bg {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 5px solid map-get(map-get($paletteNew, gray), gray1);
          border-radius: 50%;
          width: 196px;
          height: 196px;
          background: map-get(map-get($palette, primary), 3);
          overflow: hidden;

          img {
            width: 80%;
          }
        }
      }
      .page-header-company-title-container {
        margin-left: 5px;
        .page-header-company-title {
          margin-bottom: 10px;

          @media screen and (max-width: map-get($breakpoints, medium)) {
            margin-top: -30px;
            padding: 0 15px;
            text-align: center;
          }
        }
      }
    }

    .page-header-company-details {
      width: 45%;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 50%;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        padding: 0px 24px;
        width: 100%;
      }
      .page-header-company-details-desc {
        padding-left: 20px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          padding-left: 0px;
          padding-right: 20px;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          padding-left: 0px;
          padding-right: 0px;
        }

        a {
          color: map-get(map-get($paletteNew, gray), gray5);
          display: block;
          text-decoration: underline;
          margin-top: 10px;
        }
        .page-header-company-signin {
          margin-top: 20px;
          display: block;
        }
      }
      span {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: map-get(map-get($paletteNew, gray), gray3);
        }
      }
    }
    .page-header-company-share {
      margin-top: 20px;
    }
  }

  .section-seperator {
    margin-bottom: 60px;
  }

  .section-line-seperator {
    border-bottom: 0.5px solid map-get(map-get($paletteNew, gray), gray3);
  }

  .unicorn-details-section {
    color: map-get(map-get($paletteNew, gray), gray5);
    padding-bottom: 30px;
    .ui.grid {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      .ui.grid > [class*='three column mobile-two'].row > .column {
        width: 50% !important;
      }
      .ui.grid > [class*='three column mobile-one'].row > .column {
        width: 100% !important;
      }
      .row > .column.mobile-section-seperator {
        margin-top: 30px;
      }
    }

    &:last-child {
      border: none;
    }
  }
}
.unicorn-table {
  margin-top: 20px;
  &.grid {
    margin-left: 0;
    margin-right: 0;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    display: none;
  }

  .column-right {
    text-align: right;
    padding-right: 50px;
  }
  .unicorn-table-header {
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);
    &.row {
      padding: 20px 0;
    }
  }
  .row.unicorn-table-body {
    padding: 20px 0;
  }
}

.unicorn-card-container {
  display: none;
  @media screen and (max-width: map-get($breakpoints,small)) {
    display: block;
    .unicorn-card {
      border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray2);
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0px;
      }
      .unicorn-card-row {
        padding-bottom: 8px;
        &.flex {
          display: flex;
          justify-content: space-between;
        }
      }
      .unicorn-card-row-line-sepretaor {
        border-bottom: 0.5px solid map-get(map-get($paletteNew, gray), gray2);
        display: block;
        height: 1px;
        width: 100%;
        margin: 5px 18px;
      }
    }
  }
}
.pager-header-summary {
  border: 1px solid map-get(map-get($paletteNew, gray), gray1);
  box-shadow: 0px 24px 48px rgba(map-get(map-get($palette, secondary), 6), 0.2);
  border-radius: 16px;
  height: 98px;
  margin-top: 40px;
  background: map-get(map-get($palette, primary), 3);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: 20px;
    height: 175px;
    display: block;
    padding: 24px 24px 0px 24px;
    box-shadow: 0px 8px 20px rgba(map-get(map-get($palette, secondary), 6), 0.2);
    min-height: 175px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;

    .unicorn-details-column {
      width: 50%;
      min-width: 50%;
      float: left;
      margin-bottom: 24px;

      label {
        margin-bottom: 5px;
      }
      div {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .ui.grid {
    width: 100%;
  }
}

.unicorn-details-column {
  color: map-get(map-get($paletteNew, gray), gray5);

  label {
    margin-bottom: 10px;
    display: block;
    &.light {
      margin-bottom: 0px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: 10px;
      }
    }
  }

  span {
    color: map-get(map-get($paletteNew, gray), gray5);
    margin: 20px 0;
    display: block;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: map-get(map-get($paletteNew, gray), gray3);
    }
  }
}

.page-tabs {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;

  @media screen and (max-width: map-get($breakpoints,small)) {
    justify-content: space-between;
  }

  .tab {
    padding-top: 16px;
    cursor: pointer;
    margin-right: 80px;

    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-right: 0px;
    }
    span.lg-tab-span {
      display: inline-block;
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: none;
      }
    }

    span.sm-tab-span {
      display: none;
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: inline-block;
      }
    }
    img {
      display: none;
    }

    &.active {
      box-shadow: inset 0px -3px 0px map-get(map-get($paletteNew, secondary), royal);
    }
    &:last-child {
      margin-right: 0px;
    }
  }
}

.blur-info {
  -webkit-filter: url('#blur');
  filter: url('#blur');
  -webkit-filter: blur(6px);
  filter: blur(6px);
  background-size: cover;
  opacity: 0.5;
}

.buy-unicorn {
  margin-top: 50px;
  img {
    margin-right: 20px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    width: 100%;
    margin-top: 40px;
  }
}

.interest-unicorn {
  img {
    margin-left: 10px;
  }
  &:hover {
    img {
      filter: invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg)
        brightness(1000%) contrast(100%);
    }
  }
}

.page-header-fact-container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  @media screen and (max-width: map-get($breakpoints, medium)) {
    margin-top: 30px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: 20px;
  }
  .page-header-fact-col {
    flex-direction: column;
    display: flex;

    &.page-header-fact-col-num {
      text-align: right;
      margin-right: 20px;
    }
  }
  .page-header-fact-number {
    color: map-get(map-get($paletteNew, secondary), royal);
  }
  .page-header-fact-desc {
    color: map-get(map-get($paletteNew, gray), gray5);
  }
}

.mosaic-modal {
  .mosaic-modal-section {
    margin-bottom: 20px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      padding: 0 10px;
    }
  }
}
