.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .box {
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
}

.cropper-container img {
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}

.cropper-wrap-box,
.cropper-canvas,
.cropper-drag-box,
.cropper-crop-box,
.cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.cropper-wrap-box,
.cropper-canvas {
  overflow: hidden;
}

.cropper-drag-box {
  background-color: map-get(map-get($palette, primary), 3);
  opacity: 0;
}

.cropper-modal {
  background-color: map-get(map-get($paletteNew, gray), gray5);
  opacity: 0.5;
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid map-get(map-get($paletteNew, secondary), royal);
  outline-color: rgba(map-get(map-get($paletteNew, secondary), royal), 0.75);
  overflow: hidden;
  width: 100%;
}

.cropper-dashed {
  border: 0 dashed map-get(map-get($paletteNew, gray), gray1);
  display: block;
  opacity: 0.5;
  position: absolute;
}

.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: calc(100% / 3);
  left: 0;
  top: calc(100% / 3);
  width: 100%;
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: calc(100% / 3);
  top: 0;
  width: calc(100% / 3);
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}

.cropper-center::before,
.cropper-center::after {
  background-color: map-get(map-get($paletteNew, gray), gray1);
  content: ' ';
  display: block;
  position: absolute;
}

.cropper-center::before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}

.cropper-center::after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}

.cropper-face {
  background-color: map-get(map-get($palette, primary), 3);
  left: 0;
  top: 0;
}

.cropper-line {
  background-color: map-get(map-get($paletteNew, secondary), royal);
}

.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}

.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}

.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}

.cropper-point {
  background-color: map-get(map-get($paletteNew, secondary), royal);
  height: 5px;
  opacity: 0.75;
  width: 5px;
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}

.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}

.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}

.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}

.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}

.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}

@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}

@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}

@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}

.cropper-point.point-se::before {
  background-color: map-get(map-get($paletteNew, secondary), royal);
  bottom: -50%;
  content: ' ';
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}

.cropper-hidden {
  display: none !important;
}

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed;
}

.img-preview {
  overflow: hidden;
}

.rotate-btn {
  width: 35px;
  height: 35px;
  cursor: pointer;
  background: transparent;
  border: 0px;
  margin: 20px 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.drop-zone-container-with-thumb {
  display: flex;

  .dropzone {
    width: 100%;
  }
  .dropzone-with-thumb-img {
    width: 100%;
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 80%;
    }
  }
  .upload-thumb-img-container {
    height: 50px;
    width: 50px;
    margin-left: 15px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background: map-get(map-get($paletteNew, gray), gray1);
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-thumb-img {
      width: 100%;
      border-radius: 8px;
    }
    .delete-thumb {
      height: 50px;
      width: 50px;
      background: rgba(
        $color: map-get(map-get($palette, primary), 3),
        $alpha: 0.3
      );
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      align-items: center;
      display: flex;
      justify-content: center;

      &:hover {
        background: rgba(
          $color: map-get(map-get($palette, primary), 3),
          $alpha: 0.5
        );
      }
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}
@media screen and (max-width: map-get($breakpoints, small)) {
  .modal {
    &.upload-document-modal {
      width: 100%;
      height: 100%;
      .title-group {
        top: 0px;
        position: relative;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
      .modal-inner-content {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
