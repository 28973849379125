.account-banner-container {
  background: map-get(map-get($paletteNew, gray), gray1);
  border-left: 8px solid map-get(map-get($paletteNew, secondary), royal);
  padding: 16px;
  margin-top: -40px;
  &.mb-40 {
    margin-bottom: 40px;
  }

  .account-banner-header,
  .account-banner-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .account-banner-body {
    margin-top: 8px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }
    a {
      color: map-get(map-get($paletteNew, secondary), royal);
      cursor: pointer;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-left: auto;
      }
    }
  }

  .account-banner-close {
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -16px;
    margin-right: -16px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -28px;
  }
}

.account-funding-banner-container {
  box-shadow:
    0 0 10px 0px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0 0 0 2px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  position: relative;
  border-radius: 12px;
  margin-top: -40px;
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -28px;
  }

  .account-funding-banner-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 26px 42px;
    align-items: center;
    cursor: pointer;
    background-color: map-get(map-get($paletteNew, secondary), citron);
    border-radius: 12px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
    // NOTE: this is stand alone case
    .account-funding-banner-copy {
      width: 40%;
      color: map-get(map-get($paletteNew, primary), accessibleIndigo);
      @media screen and (max-width: map-get($breakpoints,medium)) {
        width: 100%;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-bottom: 24px;
      }
      .title {
        font-family: 'CodecColdBold', 'Source Serif 4' !important;
      }
      .subtitle {
        font-family: 'CodecColdRegular', 'Source Serif 4' !important;
      }
    }
  }
  .account-funding-banner-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .account-funding-banner-progress {
    display: flex;
    flex-direction: column;
    width: 60%;
    @media screen and (max-width: map-get($breakpoints,medium)) {
      width: 110%;
    }
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 110%;
    }
    .progress-bar {
      display: flex;
      flex-direction: row;
      text-align: center;
      justify-content: space-between;
      li {
        position: relative;
        width: 25%;
        margin: 0 auto;
        @media screen and (max-width: map-get($breakpoints,medium)) {
          width: 23%;
          margin: 0;
        }
        @media screen and (max-width: map-get($breakpoints,small)) {
          width: 20%;
          margin: 0;
        }
        &:before {
          display: block !important;
          text-align: center !important;
          height: 20px;
          width: 20px;
        }
        &.active {
          list-style-image: url(../../images/progress-bar-active24.svg);
          list-style-position: outside;
          color: map-get(map-get($paletteNew, primary), connectedCobalt);
          @media screen and (max-width: map-get($breakpoints,small)) {
            list-style-image: url(../../images/progress-bar-active18.svg);
          }
        }
        &.checked {
          list-style-image: url(../../images/progress-bar-checked24.svg);
          list-style-position: outside;
          color: map-get(map-get($paletteNew, primary), connectedCobalt);
          @media screen and (max-width: map-get($breakpoints,small)) {
            list-style-image: url(../../images/progress-bar-checked18.svg);
          }
        }
        &.inactive {
          list-style-image: url(../../images/progress-bar-inactive24.svg);
          list-style-position: outside;
          color: map-get(map-get($paletteNew, gray), gray3);
          &::after {
            background-color: map-get(map-get($paletteNew, gray), gray3);
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            list-style-image: url(../../images/progress-bar-inactive18.svg);
          }
        }
        &::after {
          content: '';
          position: absolute;
          width: 70%;
          height: 1px;
          background-color: map-get(
            map-get($paletteNew, primary),
            connectedCobalt
          );
          top: 12px;
          left: -37%;
          @media screen and (max-width: map-get($breakpoints,medium)) {
            left: -45%;
          }
          @media screen and (max-width: map-get($breakpoints,small)) {
            width: 90%;
            left: -66%;
            top: 9px;
          }
        }
        &:first-child::after {
          content: none;
        }
      }
    }
  }
}

.top-banner-contanier {
  position: relative;
  margin-top: -42px;
  margin-bottom: 12px;
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -32px;
  }
  .account-banner-close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .account-banner-body {
    margin-top: 4px;
    .account-banner-content {
      color: map-get(map-get($paletteNew, gray), gray4);
    }
  }
}
