.identity-verification {
  .identity-verification-container {
    margin: 32px 0;
  }
  .header {
    display: block;
  }
  .body {
    display: flex;
    flex-direction: column;

    .body-container {
      display: flex;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        flex-direction: column;
      }
    }

    .copy-container {
      width: 554px;
      margin-right: 32px;

      .copy {
        color: map-get(map-get($paletteNew, gray), gray4);
      }
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: auto;
        margin: 0;
      }
    }

    .identity-link-container {
      min-width: 320px;
      border-radius: 8px;
      background: rgba(map-get(map-get($palette, primary), 3), 0.86);
      box-shadow:
        0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
        inset 0px 0px 0px 1px
          rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 24px;

      .identity-link {
        cursor: pointer;
        width: fit-content;
        display: block;
        text-align: left;
        text-decoration: underline;
        &:last-child {
          margin-top: 24px;
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          text-decoration: none;
          &:last-child {
            margin: 16px 0;
          }
        }
      }
      @media screen and (max-width: map-get($breakpoints, medium)) {
        box-shadow: none;
        background-color: map-get(map-get($palette, primary), 3);
        border-radius: 0;
        margin: 8px 0;
        padding: 0;
      }
    }

    .verification-btn {
      display: flex;
      align-items: center;
      & > img {
        margin: 0 16px 0 0;
      }
    }
  }
}

.verification-btn-container {
  margin: 16px 0;
  width: 40%;
  & > button {
    width: 100%;
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    width: 100%;
    margin: 0;
    & > button {
      margin: 0;
    }
  }
}
