.drop-zone-container {
  width: 100%;
  .with-cam {
    width: 90%;
  }
  div {
    &:focus {
      outline: none;
    }
  }
  &:focus {
    outline: none;
  }
  .progress-bar {
    height: 3px;
    background-color: map-get(map-get($paletteNew, secondary), royal);
    transform: translateY(-3px);
    transition: all 0.1s ease-in-out;
    border-radius: 8px;
  }
}
