.ria-dashboard {
  .ria-client-list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray1);
    padding: 10px 16px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      align-items: flex-start;
    }
    div,
    a {
      display: flex;
      &.flag {
        position: relative;
        top: auto;
        margin-right: 4px;
      }
    }

    .col-name {
      width: 25%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
    }
    .col-status {
      width: 38%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
    }
    .col-actions {
      width: 18%;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        padding-left: 20%;
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }

      .btn {
        margin-top: 0px;
        margin-bottom: 0px;
        min-width: 120px;
      }
    }
    .col-investment {
      width: 18%;
      justify-content: flex-end;
      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
    }
  }

  .flag-container {
    margin-left: 48px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-left: 0px;
    }
  }
  .ria-client-list-header {
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);
    text-transform: uppercase;
    .col-investment {
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: -25px;
      }
    }
  }

  .verified {
    margin-left: 10px;
    margin-top: 3px;
  }
  .error-icon {
    margin-right: 32px;
    margin-top: 3px;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      margin-right: 12px;
    }
  }
  .view-icon {
    margin-left: 16px;
  }

  .error-info {
    color: map-get(map-get($paletteNew, action), errorRed);
  }
  .act-as-myself-container {
    flex-direction: column;
    margin-right: 20px;
    align-items: flex-end;
    .btn {
      max-width: 140px;
    }
  }

  .action-sub-title {
    color: map-get(map-get($paletteNew, gray), gray3);
    margin-top: -5px;
  }
}

.ria-banner {
  .inner-container {
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 0 46px;
    }
  }
}

.add-new-client-link-container {
  margin-bottom: 32px;
  .add-new-client-link {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }

    .add-new-client-link-copy,
    .add-new-client-link-link {
      width: 48%;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
      }
    }
    .add-new-client-link-link {
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-top: 16px;
      }
    }
  }
}

.ria-accreditation-container {
  .content {
    margin-bottom: 24px;
  }

  .btn-group {
    margin-top: 32px;
  }
}
