.how-it-works-modal {
  max-width: 692px;

  .modal-inner-content {
    padding: 46px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 24px;
    }
  }
  .grid {
    width: 100%;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: auto;
    }
    .row {
      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column-reverse;
        display: flex;
      }

      .column {
        padding-left: 0px;
        padding-right: 0px;

        img {
          @media screen and (max-width: map-get($breakpoints, small)) {
            width: 100%;
          }
        }
        &.modal-copy {
          padding-left: 16px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            padding-right: 16px;
          }
        }
      }
    }
  }

  .btn {
    width: 100%;
    margin-top: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .footnote {
    color: map-get(map-get($paletteNew, gray), gray4);
    margin-top: 16px;
  }

  .modal-footer {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column-reverse;
    }
  }
}
