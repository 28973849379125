.schedule-meetings-button-wrapper {
  position: fixed;
  right: 16px;
  bottom: 16px;

  .schedule-meetings-close {
    position: absolute;
    top: -15px;
    right: -11px;
    cursor: pointer;
  }

  .schedule-meetings-button {
    width: 120px;
    height: 80px;
    border-radius: 8px;
    box-shadow: 0px 3px 4px
      rgba(map-get(map-get($paletteNew, gray), gray5), 0.25);
    background: map-get(map-get($paletteNew, secondary), royal);
    color: map-get(map-get($palette, primary), 3);
    display: flex;
    flex-direction: column;
    padding: 14px 0;
    cursor: pointer;

    span {
      width: 100%;
      text-align: center;
      display: block;
    }

    img {
      width: 30px;
      height: 30px;
      margin-left: 15px;
      margin-bottom: 5px;
    }
  }
}
