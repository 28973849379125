.activate-container {
  text-align: center;
  max-width: 725px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 78px;
    margin-bottom: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      height: 30px;
      margin-bottom: 0px;
    }
  }
}
