.error-container {
  display: flex;
  .error {
    background-color: map-get(map-get($palette, error), 3);
    color: map-get(map-get($palette, error), 2);
    padding: 16px 24px;
    border-radius: 5px;
    box-shadow:
      0 0 0 1px rgba(map-get(map-get($palette, error), 2), 0.4) inset,
      0 0 0 0 transparent;
    text-align: left;

    .header {
      display: block;
    }
    p {
      opacity: 0.85;
      margin: 0.25em 0;
    }
  }
  &.full-width {
    display: block;
  }
}
