.nonclickable-grid-container {
  margin: 32px 0;

  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-top: 24px;
  }
  &__list {
    margin-top: 12px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 0px;
    }
  }
  &__title {
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding-bottom: 8px;
      border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);
    }
  }
  &__no-data {
    color: map-get(map-get($paletteNew, gray), gray3);
    text-align: center;
    margin-top: 8px;
    padding-top: 20px;
    margin-bottom: 60px;
    border-top: 1px solid map-get(map-get($paletteNew, gray), gray3);

    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 12px;
      border-top: 0px;
    }
  }
  &__columns {
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: baseline;
    padding: 8px 16px;
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: none;
    }
    &.cash_history {
      grid-template-columns: 0.5fr 2fr 0.75fr;
    }
    &.rewards_history {
      grid-template-columns: 0.5fr 1fr 0.75fr 1.5fr;
    }

    span:not(:first-child) {
      padding-left: 48px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        padding-left: 0px;
      }
    }
  }

  &__row {
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray2);
    padding: 0 16px;
    align-items: center;
    position: relative;
    min-height: 56px;

    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      min-height: auto;
      padding: 12px 0;
    }
    &.cash_history {
      grid-template-columns: 0.5fr 2fr 0.75fr;
    }
    &.rewards_history {
      grid-template-columns: 0.5fr 1fr 0.75fr 1.5fr;
    }
    span:not(:first-child) {
      padding-left: 48px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        padding-left: 0px;
      }
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      min-height: 80px;
    }
    &__full-flex {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
