.breadcrumb-container {
  padding: 5px 0;
  display: flex;
  margin-bottom: 16px;

  .breadcrumb-link {
    color: map-get(map-get($paletteNew, secondary), royal);
    cursor: pointer;
  }
  img {
    margin: 0px 24px;
    width: 5px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin: 0px 16px;
    }
  }
}
