.investor-status-sub-title {
  display: flex;
  align-items: baseline;
  margin: 0;

  .accredaition-status {
    margin-left: 10px;
    display: flex;
    @media screen and (max-width: map-get($breakpoints, small)) {
      align-items: center;
    }
    img {
      margin-right: 4px;
    }

    span {
      color: map-get(map-get($paletteNew, gray), gray4);
      line-height: 1;
    }
  }
}
