.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 150px;
  background-color: map-get(map-get($palette, primary), 3);
  box-shadow:
    0 0 10px 0px rgba(map-get(map-get($palette, secondary), 6), 0.08),
    inset 0 0 0 1px rgba(map-get(map-get($palette, secondary), 6), 0.08);
  height: 88px;
  z-index: 998;
  position: relative;
  .logo {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    img {
      width: 162px;
      height: 30px;
    }
  }
  .main-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 85%;
    // NOTE: stand alone style for top navigation
    .item {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin: 15px 15px;
      height: 100%;
      cursor: pointer;
      font-size: 16px;
      color: map-get(map-get($paletteNew, primary), accessibleIndigo);
      font-family: 'CodecColdBold', 'Source Serif 4' !important;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }

      .nav-button {
        background-color: map-get(map-get($paletteNew, secondary), royal);
        color: map-get(map-get($palette, primary), 3);
        padding: 8px 15px;
        margin-left: -1rem;
        border-radius: 6px;
        font-size: 16px;
        font-family: 'CodecColdBold', 'Source Serif 4' !important;
        &:hover {
          background-color: lighten(
            map-get(map-get($paletteNew, secondary), royal),
            3%
          );
        }
        @media screen and (max-width: map-get($breakpoints, medium)) {
          margin-left: 0;
          background-color: transparent;
          color: map-get(map-get($paletteNew, gray), gray5);
          padding: 0 10px;
          &:hover {
            background-color: transparent;
          }
        }
      }
      &:hover:not(.sign-in):not(.signup):not(.nav-button):not(.dropdown):not(
          .account
        ):not(.mobile) {
        box-shadow: inset 0px -3px 0px map-get(map-get($paletteNew, primary), connectedCobalt);
      }
      &:hover.sign-in {
        color: map-get(map-get($paletteNew, secondary), royal);
      }
      &.active {
        color: map-get(map-get($paletteNew, primary), connectedCobalt);
      }
    }
    .item.active:not(.signup) {
      @media screen and (min-width: map-get($breakpoints, medium)) {
        box-shadow: inset 0px -3px 0px map-get(map-get($paletteNew, primary), connectedCobalt);
      }
    }
    .dropdown_menu {
      z-index: 100;
      position: absolute;
      margin-left: -80px;
      display: flex;
      flex-direction: column;
      top: 64px;
      background-color: map-get(map-get($palette, primary), 3);
      box-shadow: 0px 4px 10px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.1);
      border-radius: 12px;
      width: 240px;
      display: none;
      @media screen and (max-width: map-get($breakpoints,medium)) {
        margin-left: -110px;
      }
    }
    .dropdown:hover > .dropdown_menu {
      display: block;
    }
    .dropdown_menu-6 {
      animation: growDown 250ms ease-in-out forwards;
      transform-origin: top center;
    }
    @keyframes growDown {
      0% {
        transform: scale(0.5);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }
    .dropdown {
      .dropdown-title {
        font-family: 'CodecColdBold', 'Source Serif 4' !important;
        font-size: 16px;
        margin-right: 8px;
      }
      .item {
        padding: 10px 15px;
        margin: 0;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: map-get(map-get($paletteNew, primary), accessibleIndigo);
        text-transform: capitalize;
        img {
          margin-right: 20px;
        }
        span {
          font-family: 'CodecColdRegular', 'Source Serif 4' !important;
          font-size: 16px;
        }
        &:hover {
          border: none;
          color: map-get(map-get($paletteNew, gray), gray5);
        }
        &.active {
          opacity: 0.8;
          box-shadow: none;
          color: map-get(map-get($paletteNew, primary), connectedCobalt);
        }
      }
      &.cobalt {
        color: map-get(map-get($paletteNew, primary), connectedCobalt);
      }
    }
    &.mobile-active {
      display: none;
    }
  }

  .toggle {
    display: none;
  }
  .animate-up {
    img {
      transform: rotate(0deg);
      transition: 400ms;
    }
    &.down {
      img {
        transform: rotate(180deg) !important;
      }
    }
    &.up {
      img {
        transform: rotate(0deg) !important;
      }
    }
    &:hover {
      img {
        transform: rotate(180deg);
        transition: 400ms;
      }
    }
  }

  @media screen and (max-width: map-get($breakpoints,large)) {
    padding: 0 60px;
    .main-nav {
      width: 85%;
      flex-wrap: nowrap;
      .item {
        margin: 15px 5px;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, medium)) {
    padding: 0 40px;
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 0 1.5rem;
    height: 73px;
  }
}

@media screen and (max-width: map-get($breakpoints,medium)) {
  .nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .main-nav {
      display: none;
    }
    .main-nav .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 12px 24px;
      margin: 0px;
      color: map-get(map-get($paletteNew, gray), gray5);
      text-transform: capitalize;
      font-size: 16px;
    }
    .dropdown-active {
      background-color: map-get(map-get($palette, primary), 3);
      .item.account {
        .arrow {
          position: absolute;
          right: 1rem;
          width: 10px;
          height: 10px;
          border: solid map-get(map-get($paletteNew, gray), gray5);
          border-width: 0 3px 3px 0;
          padding: 3px;
        }
        .down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
        .up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }
    }

    .main-nav.mobile-active {
      background-color: map-get(map-get($palette, primary), 3);
      box-shadow: 0px 4px 20px
        rgba(map-get(map-get($paletteNew, gray), gray5), 0.15);
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      height: var(--app-height);
      z-index: 100;
      padding: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .mobile-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 90%;
        text-align: center;
        width: 100%;
        overflow: scroll;
        .mobile-nav-items {
          margin-top: 48px;
          font-size: 24px;
          font-weight: 600;
          font-family: 'CodecColdRegular', 'Source Serif 4' !important;
          color: map-get(map-get($paletteNew, primary), accessibleIndigo);
          &.cobalt {
            color: map-get(map-get($paletteNew, primary), connectedCobalt);
          }
          img {
            margin-left: 8px;
          }
        }
        p {
          font-size: 24px;
          font-family: 'CodecColdRegular', 'Source Serif 4' !important;
          margin-top: 24px;
        }
      }
      .mobile-container::-webkit-scrollbar {
        display: none;
      }
    }
    .mobile-nav-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      z-index: 101;
      position: relative;
      .toggle-icon {
        display: block;
        height: 100%;
        padding: 5px;
        z-index: 999;
        margin-top: 15px;
      }
      #nav-icon {
        width: 28px;
        height: 36px;
        position: relative;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
      }

      #nav-icon span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #000;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
      }

      #nav-icon span:nth-child(1) {
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon span:nth-child(2) {
        top: 18px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      #nav-icon.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 11px;
        left: 0px;
      }

      #nav-icon.open span:nth-child(2) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 30px;
        left: 0px;
      }
    }
  }
}

@media screen and (max-width: map-get($breakpoints,small)) {
  .nav-container {
    justify-content: center;
  }
}
