.invest-detail-container {
  .page-container {
    min-height: auto;
    padding: 0px;

    @media screen and (max-width: map-get($breakpoints, medium)) {
      padding: 0px 40px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 0px 24px;
    }
  }
  .invest-header-container {
    padding-bottom: 24px;
    .invest-details-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 48px 0 20px 0;

      @media screen and (max-width: map-get($breakpoints, small)) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 0;
      }

      .invest-header {
        width: 50%;
        .toggle-switch-container {
          margin: 40px 0 0 0;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin: 20px 0 0 0;
          }
        }
        .company-available {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .company-details {
          height: 50%;
          display: flex;
          flex-direction: column;
          & > a {
            font-weight: $semi-bold;
            text-decoration: underline;
          }
          // Note: stand-alone
          &__subheader {
            font-family: 'CodecColdRegular' !important;
            margin: 20px 0 5px 0;
          }
          // Note: stand-alone
          &__vertical {
            margin: 30px 0;
            font-family: 'Source Sans Pro' !important;
            @media screen and (max-width: map-get($breakpoints, small)) {
              margin: 0;
            }
          }
        }

        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 100%;
          padding-bottom: 16px;

          .company-details {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
        .invest-logo {
          width: 188px;
          height: 66px;
          object-fit: contain;
          @media screen and (max-width: map-get($breakpoints, small)) {
            width: 144px;
            height: 42px;
          }
        }
        &.not-signed-header {
          margin-top: 40px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
          }
        }
        &.fully-subscribed-header {
          flex-direction: row;
          display: flex;
          align-items: center;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            flex-direction: column;
            align-items: flex-start;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            flex-direction: row;
            align-items: center;
          }
        }
      }

      .invest-order {
        width: 50%;
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 100%;
        }
        .invest-order-header {
          border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray2);
          display: flex;
          justify-content: space-evenly;
          margin-bottom: 20px;
          min-height: 54px;
          div {
            border-right: 1px solid map-get(map-get($paletteNew, gray), gray2);
            padding-left: 18px;
            width: 30%;
            @media screen and (max-width: map-get($breakpoints, small)) {
              padding-left: 5px;
            }
            &:nth-child(2) {
              width: 39%;
            }
            &:first-child {
              padding-left: 0px;
            }
            // NOTE: stand alone
            &:last-child {
              width: 31%;
              border-right: 0px;
              color: map-get(map-get($paletteNew, primary), connectedCobalt);
            }
            span {
              display: block;
              @media screen and (max-width: map-get($breakpoints, small)) {
                line-height: 20px;
              }
              &.blured {
                -webkit-filter: url('#blur');
                filter: url('#blur');
                -webkit-filter: blur(3px);
                filter: blur(3px);
                background-size: cover;
              }
              &.fully_subscribed {
                @media screen and (max-width: map-get($breakpoints, small)) {
                  margin-bottom: 5px;
                }
              }
              &.sub-header {
                color: map-get(map-get($paletteNew, gray), gray3);
              }
            }
          }
        }
        .invest-order-box {
          padding: 16px 22px;
          margin-bottom: 0px;
          @media screen and (max-width: map-get($breakpoints, small)) {
            padding: 16px 12px;
          }

          .invest-order-row {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            margin-bottom: 5px;

            .promo-text {
              color: map-get(map-get($paletteNew, gray), gray3);
              display: block;
              @media screen and (max-width: map-get($breakpoints, small)) {
                display: flex;
                flex-wrap: wrap;
              }
            }

            &__tier-price {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin-bottom: 16px;

              &__copy-container {
                display: flex;
                align-items: center;
                img {
                  margin-right: 6px;
                }
              }

              &__left {
                display: flex;
                flex-direction: column;
                &__link {
                  color: map-get(
                    map-get($paletteNew, primary),
                    connectedCobalt
                  );
                  cursor: pointer;
                  &__not-clickable {
                    cursor: default;
                  }
                }
              }

              &__right {
                color: map-get(map-get($paletteNew, primary), connectedCobalt);
              }
            }

            &__separator {
              border-bottom: 1px solid
                map-get(map-get($paletteNew, gray), gray2);
              margin-bottom: 20px;
            }

            .linqto-bucks-available {
              color: map-get(map-get($paletteNew, gray), gray4);
            }

            .input-group.checkbox .checkbox-label {
              margin: 0px;
            }

            .input-group.checkbox {
              margin-top: 0px;
            }
            select {
              max-width: 65%;
            }
            &.top-aligned {
              align-items: flex-start;
            }
            &.slider-row {
              margin: 24px 0;
            }
            &.check-box-row {
              align-items: flex-start;
              .linqto-checkbox-container {
                height: 48px;

                &.full-row {
                  width: 100%;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  @media screen and (max-width: map-get($breakpoints, small)) {
                    justify-content: space-between;
                  }

                  .linqto-bucks-available {
                    padding-left: 8px;
                  }
                }
                &.half-row {
                  width: 50%;
                  @media screen and (max-width: map-get($breakpoints, medium)) {
                    width: 100%;
                    justify-content: space-between;
                    display: flex;
                    align-items: center;
                  }
                  .linqto-bucks-available {
                    padding-left: 25px;
                    @media screen and (max-width: map-get($breakpoints, medium)) {
                      padding-left: 0px;
                    }
                  }
                }
              }

              .linqto-bucks-percent {
                color: map-get(map-get($paletteNew, gray), gray4);
                max-width: 50%;
                @media screen and (max-width: map-get($breakpoints, small)) {
                  max-width: 100%;
                }
              }
              @media screen and (max-width: map-get($breakpoints, small)) {
                margin-top: -16px;
                flex-direction: column;
              }
            }
            &.linqto-bucks-not-used {
              color: map-get(map-get($paletteNew, gray), gray4);
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }

        .place-order-copy {
          color: map-get(map-get($paletteNew, gray), gray3);
        }
        &.fully-subscribed-cta {
          margin: 48px 0;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin: 0;
          }
        }
        &.sign-in-cta {
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
          }
        }
      }
      &__bottom-info {
        display: flex;
        flex-direction: column;
        display: flex;
        margin-bottom: 100px;
        & > a {
          text-decoration: underline;
        }
      }
    }
  }

  .invest-tabs-container {
    &__page-tabs {
      display: flex;
      .tab {
        cursor: pointer;
        font-weight: $medium;
        margin-right: 40px;
        color: map-get(map-get($paletteNew, primary), accessibleIndigo);
        // NOTE: stand alone
        & > span {
          font-family: 'CodecColdBold' !important;
        }
        &.active {
          color: map-get(map-get($paletteNew, primary), connectedCobalt);
          border-bottom: 3px solid map-get(map-get($paletteNew, primary), connectedCobalt);
          padding-bottom: 10px;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 30px;
        }
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: -49px;
      }
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    .desktop-content {
      display: none;
    }
  }

  .invest-tab {
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 8px 0px 0px;
    }
    .row {
      span {
        color: map-get(map-get($paletteNew, gray), gray4);
        &.dark {
          color: map-get(map-get($paletteNew, gray), gray5);
        }
        &.padding-left {
          padding-left: 12px;
        }
        &.full-line {
          display: block;
        }
        &.one-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.mobile-line {
          @media screen and (max-width: map-get($breakpoints, small)) {
            display: inline;
          }
        }
        &.half-line {
          display: inline-block;
          width: 50%;
        }
        &.under-line {
          text-decoration: underline;
          cursor: pointer;
        }
        &.sub-title {
          margin-bottom: 8px;
        }
        &.no-title {
          margin-top: 30px;
          display: inline-block;
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 0px;
          }
        }
        &.mobile-spaced {
          @media screen and (max-width: map-get($breakpoints, small)) {
            margin-top: 24px;
          }
        }
      }
      .website-link {
        color: map-get(map-get($paletteNew, gray), gray4);
        text-decoration: underline;
        margin-left: -5px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          display: block;
          margin-top: 16px;
          margin-bottom: -16px;
          margin-left: 0px;
        }
      }
      .desc {
        color: map-get(map-get($paletteNew, gray), gray4);
      }
      .two-cols-text {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        @media screen and (max-width: map-get($breakpoints, small)) {
          -webkit-column-count: 1;
          -moz-column-count: 1;
          column-count: 1;
        }
      }
      &.rounded {
        border-radius: 8px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          border-radius: 0px;
        }
      }
      &.bordered {
        border: 0.5px solid map-get(map-get($paletteNew, gray), gray3);
        padding: 16px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          border: none;
          padding: 16px 0px;
        }
      }
      &.floated-right {
        float: right;
        max-width: 202;
        @media screen and (max-width: map-get($breakpoints, small)) {
          float: none;
          max-width: auto;
        }
      }
      .title {
        color: map-get(map-get($paletteNew, gray), gray5);
        margin-bottom: 42px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-bottom: 24px;
        }
      }
      li {
        margin-left: 20px;
        margin-bottom: 36px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-bottom: 24px;
        }
      }
    }

    .grid > .row {
      > .column > .grid {
        margin-top: 0px;
        margin-bottom: 0px;

        > .row {
          padding-top: 0;
          padding-bottom: 0;

          &.unicorn-table-header {
            padding-bottom: 5px;
            margin-top: 24px;
          }
        }
      }
    }
    .section-seprator {
      border-bottom: 0.5px solid map-get(map-get($paletteNew, gray), gray2);
      display: flex;
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: none;
      }
    }
    .line-space {
      margin-top: 16px;
    }
    .mobile-grey {
      @media screen and (max-width: map-get($breakpoints, small)) {
        background-color: map-get(map-get($paletteNew, gray), gray1);
        margin: 16px -1.5rem 0px;
        padding: 24px 24px 0;
        min-width: calc(100% + 2rem);
      }
    }
  }

  .summary-container {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    column-gap: 100px;
    &__left {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
      }
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .valuation-over-time {
    width: 100%;
    &__container {
      display: grid;
      grid-template-columns: 1.25fr 1fr;
      column-gap: 100px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  .valuation-block {
    padding: 24px !important;
    border-radius: 12px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px;
    height: fit-content;
    &__top {
      padding-bottom: 20px;
    }
    &__bottom {
      border-top: 1px solid map-get(map-get($paletteNew, gray), gray2);
      padding-top: 35px;
    }
    & a {
      display: block;
      cursor: pointer;
      color: map-get(map-get($paletteNew, primary), connectedCobalt) !important;
      margin: 30px 0 10px 0;
    }
    &__info {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      & > .margin-bottom {
        margin-bottom: 20px;
      }
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    &__right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px 30px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        gap: 10px 20px;
      }
    }
    &__valuation {
      color: map-get(map-get($paletteNew, primary), openOcean) !important;
      font-size: 60px;
      font-style: normal;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      max-width: 100%;
    }
  }

  .subheader-title {
    display: flex;
    & h6 {
      margin-right: 5px;
    }
  }

  .invest-table {
    margin-left: 0rem;
    margin-right: 0rem;
    .unicorn-table-header {
      .column:first-child {
        padding-left: 0;
      }
      .column-right {
        padding-right: 16px;
        @media screen and (max-width: map-get($breakpoints, medium)) {
          padding-right: 8px;
        }
        @media screen and (max-width: map-get($breakpoints, small)) {
          padding-right: 6px;
        }
      }
    }
    .column {
      &.max-15 {
        max-width: 15%;
      }
      &.max-12 {
        max-width: 12%;
      }
      &.max-23 {
        max-width: 23%;
      }
      &.min-35 {
        min-width: 35%;
      }
      &.min-45 {
        min-width: 45%;
      }
      &.min-65 {
        min-width: 65%;
      }
      &.max-m-25 {
        @media screen and (max-width: map-get($breakpoints, small)) {
          max-width: 25%;
        }
      }
      &.min-m-50 {
        @media screen and (max-width: map-get($breakpoints, small)) {
          min-width: 50%;
        }
      }
    }
    .unicorn-table-body {
      border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray2);
      .column {
        min-height: 50px;
        color: map-get(map-get($paletteNew, gray), gray4);
        &:first-child {
          padding-left: 0px;
        }
        &.column-right {
          align-items: flex-end;
          padding-right: 16px;
          @media screen and (max-width: map-get($breakpoints, medium)) {
            padding-right: 8px;
          }
          @media screen and (max-width: map-get($breakpoints, small)) {
            padding-right: 6px;
          }
        }
        &.column-gray {
          background: map-get(map-get($paletteNew, gray), gray1);
        }
        &.column-bottom {
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }
        &.dark-color {
          color: map-get(map-get($paletteNew, gray), gray3);
        }
      }
    }

    &.column-table {
      background: none;
      .row.unicorn-table-body {
        align-items: flex-start;
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        .column {
          padding-top: 16px;
          padding-bottom: 16px;
        }
      }
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: flex;
      }
    }
  }

  .container-margin {
    margin-top: 30px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin: 15px 0 !important;
    }
  }

  .subheader-extra-margin {
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin: 30px 0;
    }
  }

  .key-facts {
    & > h6 {
      color: map-get(map-get($paletteNew, primary), accessibleIndigo);
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 30px;
      }
    }
  }

  .subheader-extra-margin {
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin: 30px 0;
    }
  }

  .key-facts {
    &__list {
      margin: 20px 0 20px 25px;
    }
    &__fact {
      color: map-get(map-get($paletteNew, gray), gray3);
      margin: 0 !important;
    }
  }

  .fact-container {
    margin-bottom: 24px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-bottom: 16px;
    }

    .fact-items {
      overflow: hidden;
      &.maxHeight {
        max-height: 215px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          max-height: 195px;
        }
      }
    }
    .fact-title {
      margin: 16px 0;
    }
    .fact-item {
      margin-bottom: 32px;
      color: map-get(map-get($paletteNew, gray), gray4);
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-bottom: 24px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    a {
      color: map-get(map-get($paletteNew, secondary), royal);
      cursor: pointer;
    }
  }
  @media screen and (max-width: map-get($breakpoints, small)) {
    .fully-subscribed-tabs-container {
      .sticky-tabs.page-tabs {
        margin-top: -125px;
      }
    }
  }
}

.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-horizontal {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.outer-container {
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
}
.chart-container {
  height: 360px;
  max-width: 500px;
  margin-top: 16px;
  margin-bottom: 10px;  
}

.mobile-chart-container {
  overflow-x: auto;
  margin-bottom: 24px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-left: -24px;
    padding-left: 24px;
  }
}

.mobile-chart-container::-webkit-scrollbar:horizontal {
  height: 3px;
}
.mobile-chart-container::-webkit-scrollbar-thumb:horizontal {
  background: map-get(map-get($paletteNew, gray), gray3);
  border-radius: 3px;
}
.mobile-chart-container::-webkit-scrollbar-track:horizontal {
  display: none;
}

.chart-footnote {
  color: map-get(map-get($paletteNew, gray), gray4);
  overflow-wrap: break-word;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 32px;
    }
  }
}

.table-footnote {
  margin: 24px 0;
  width: 100%;
  color: map-get(map-get($paletteNew, gray), gray4);
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;

  div {
    display: inline;
  }
}

.sticky-header {
  display: none;
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .sticky-tabs {
    position: fixed;
    top: 125px;
    width: 100%;
    left: 0px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom: 0.5px solid map-get(map-get($paletteNew, gray), gray4);
    background: map-get(map-get($palette, primary), 3);
    z-index: 10;
  }

  .sticky-padding {
    padding-top: 60px !important;

    &.fully-subscribed-tabs-container {
      padding-top: 0px !important;
    }
  }

  .sticky-header {
    display: block;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    max-height: 80px;
    left: 0;
    padding: 8px 24px;
    background: map-get(map-get($palette, primary), 3);
  }
  .sticky-content {
    display: none;
  }
}

.invest-details-sell-container {
  display: flex;
  justify-content: flex-end;

  .invest-order-box {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin-top: -8px;
    }

    div {
      color: map-get(map-get($palette, secondary), 7);
    }
  }
}
