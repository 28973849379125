.footer-wrapper {
  background-color: map-get(map-get($paletteNew, primary), accessibleIndigo);
  color: map-get(map-get($paletteNew, gray), white);
  padding: 150px 0;
  @media screen and (max-width: map-get($breakpoints,medium)) {
    padding: 75px 35px;
  }
  @media screen and (max-width: map-get($breakpoints,small)) {
    padding: 75px 24px;
  }

  .footer-container {
    display: flex;
    max-width: 1140px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
    }
  }

  .footer-group {
    width: 40%;
    @media screen and (max-width: map-get($breakpoints,small)) {
      width: 100%;
    }
    &.wide {
      width: 60%;
      padding-right: 90px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        width: 100%;
        padding-right: 0px;
      }
    }
  }
  div {
    &.margined {
      margin-top: 20px;
    }
  }
  .footer-logo {
    color: map-get(map-get($paletteNew, gray), gray4);
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .logo {
      height: 49px;
    }

    .footer-heading {
      color: map-get(map-get($paletteNew, gray), white);
      width: 60%;
      @media screen and (max-width: map-get($breakpoints, medium)) {
        width: 100%;
      }
      @media screen and (max-width: map-get($breakpoints,small)) {
        text-align: center;
        width: 90%;
      }
    }
  }

  .footer-copy {
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin: 20px 0px;
      border-top: 1px solid
        rgba(map-get(map-get($paletteNew, gray), white), 0.2);
      justify-content: center;
    }
    .social-links {
      margin-top: 50px;
      margin-bottom: 30px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        display: flex;
        justify-content: center;
      }
      img {
        width: 32px;
        height: 32px;
      }
      a {
        margin-right: 12px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-right: 6px;
          margin-left: 6px;
        }
      }
    }
  }
  .copyright {
    div {
      color: map-get(map-get($paletteNew, gray), gray2);
    }
    .copyright-link {
      color: map-get(map-get($paletteNew, gray), white);
      text-decoration: underline;
    }
  }
  .footer-links {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      margin-top: 40px;
    }
  }
  .nav-group {
    display: flex;
    flex-direction: column;
    width: 50%;
    .navs {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: map-get($breakpoints,small)) {
        align-items: center;
      }
      // NOTE: stand alone cases
      .nav-title {
        margin-top: 40px;
        margin-bottom: 15px;
        font-family: 'CodecColdExtraBold', 'Source Serif 4' !important;
        color: map-get(map-get($paletteNew, gray), white);
        font-size: 20px;
        @media screen and (max-width: map-get($breakpoints,small)) {
          text-align: center;
          font-size: 16px;
        }
      }
      a.nav-title {
        cursor: pointer;
        text-decoration: underline;
      }
      .item {
        &:not(:last-of-type) {
          margin-bottom: 15px;
        }
        .footer-link {
          color: map-get(map-get($paletteNew, gray), gray2);
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: map-get(map-get($paletteNew, gray), white);
          }
        }
      }
    }
  }
  .desktop-footer {
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: none;
    }
  }
  .mobile-footer {
    display: none;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: block;
    }
  }
}
