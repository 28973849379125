.origination-container {
  .origination-header {
    position: relative;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      margin-bottom: -32px;
    }
    .page-container {
      min-height: auto;
    }
    .origination-backgeound {
      width: 100%;
      height: 300px;
      object-fit: cover;
      position: absolute;
      z-index: -1;
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: none;
      }
    }
    .origination-backgeound-mobile {
      display: none;
      @media screen and (max-width: map-get($breakpoints, small)) {
        display: block;
        width: 100%;
        height: 150px;
        object-fit: cover;
      }
    }
    .origination-copy {
      max-width: 54%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        max-width: 100%;
      }
      .page-sub-title {
        margin-top: 16px;
      }
    }
  }
  .content-separator {
    border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray3);
    margin: 40px 0;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin: 16px 0;
    }
  }
  .page-container {
    padding-top: 48px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      padding: 16px 24px;
    }
  }
  .origination-body {
    .origination-body-section {
      width: 100%;
      margin: 32px 0 40px;
      padding: 40px 56px 64px 40px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        padding: 24px 16px 40px 16px;
        margin: 24px 0 56px;
      }
      .origination-body-title {
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-bottom: 24px;
        }
      }
    }
    .origination-search {
      display: flex;
      align-items: center;
      .origination-search-input {
        width: 100%;
        margin-right: 48px;
        position: relative;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-right: 0px;
        }
        .search-icon {
          position: absolute;
          right: 16px;
          top: 14px;
          z-index: 1003;
        }
      }
      .origination-search-img {
        width: 130px;
        @media screen and (max-width: map-get($breakpoints, small)) {
          display: none;
        }
      }
    }
  }
  .origination-footer {
    .origination-footer-title {
      text-align: center;
      margin-top: 16px;
      margin-bottom: 60px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
    .origination-footer-section-container {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 40px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        flex-direction: column;
      }
      .origination-footer-section {
        width: 28%;
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 100%;
          margin-bottom: 40px;
        }
        .origination-footer-img {
          width: 100%;
          height: 122px;
          margin-bottom: 32px;
          object-fit: contain;
          @media screen and (max-width: map-get($breakpoints, small)) {
            height: 122px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
