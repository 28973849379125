.add-funds-container {
  max-width: 672px;
  margin: 64px auto;
  padding: 35px 117px 48px;
  background: map-get(map-get($palette, primary), 3);
  box-shadow:
    0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0px 0px 0px 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  border-radius: 8px;

  @media screen and (max-width: map-get($breakpoints, small)) {
    padding: 0;
    box-shadow: none;
    margin: 30px auto;
  }

  .uphold-support a {
    text-decoration: none;
  }

  .available-funds {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.funding-source {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid map-get(map-get($paletteNew, gray), gray1);
  /* Tile Shadow */
  box-shadow:
    0px 0px 10px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08),
    inset 0px 0px 0px 1px rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  border-radius: 8px;
  cursor: pointer;

  &.funding-source-account {
    padding: 8px 16px;
  }

  div {
    display: flex;
    align-items: center;

    .account-icon {
      margin-right: 16px;
      width: 40px;
      height: 40px;
    }

    .account-balance {
      margin-right: 40px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin-right: 8px;
      }
    }
  }
  &.error {
    border: 1px solid $err-red;
  }
}

.order-modal .order-modal-account-item {
  div.add-funding-modal-item-right {
    margin-left: auto;
    @media screen and (max-width: map-get($breakpoints,small)) {
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      .account-arrow {
        position: absolute;
        right: 0px;
        top: 40%;
      }

      .order-modal-account-desc {
        padding-right: 24px;
      }
    }
  }
  div.add-funding-modal-item-name {
    margin-right: 20px;
    min-width: 100px;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: none;
    }
  }
  div.add-funding-modal-item-name-sm {
    display: none;
    @media screen and (max-width: map-get($breakpoints,small)) {
      display: block;
      padding-right: 18px;
    }
  }
}
.add-funding-modal-item {
  justify-content: flex-start;

  &.read-only {
    cursor: default;
    background-color: map-get(map-get($paletteNew, gray), gray1);
    box-shadow: none;

    div {
      color: map-get(map-get($paletteNew, gray), gray4);

      &.add-funding-modal-item-right {
        @media screen and (max-width: map-get($breakpoints,small)) {
          margin-right: auto;
          margin-left: 0px;
        }
      }
    }
  }
}

.funding-modal .order-uphold-error {
  margin-left: 75px;
  margin-top: 0px;
  margin-bottom: -8px;
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-left: 58px;
    margin-top: -8px;
  }
}

.funding-modal .order-modal-account-header {
  @media screen and (max-width: map-get($breakpoints,small)) {
    flex-direction: row;

    .note {
      margin-left: 16px;
      margin-top: 12px;
    }
  }
}

.funding-modal .manual-account-text {
  text-align: center;
  @media screen and (max-width: map-get($breakpoints,small)) {
    text-align: left;
  }
}

.linqto-wire-form {
  p {
    margin-bottom: 0px;
  }

  ul {
    margin-left: 20px;
  }
}

.plaids-accounts-container {
  margin-top: 32px;
}

.accounts-separator {
  border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray2);
  margin: 32px 0;
  @media screen and (max-width: map-get($breakpoints, small)) {
    margin: 16px 0;
  }
}

.add-funds-summary {
  margin: 32px 0px 24px;

  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 32px 0px 24px;
  }

  .add-funds-summary-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 4px 0;

    .transfer-from {
      min-width: 25%;
      @media screen and (max-width: map-get($breakpoints,small)) {
        min-width: 40%;
      }
    }

    .bank-name {
      text-align: right;
    }
    &.error {
      color: map-get(map-get($paletteNew, action), errorRed);
    }

    &.add-funds-summary-note {
      margin-top: 48px;
      color: map-get(map-get($paletteNew, gray), gray4);
    }
  }
}

.add-summary-verification {
  padding-top: 0px;
  margin-bottom: 16px;
  .err-msg {
    margin-bottom: 16px;
  }
}

.horizontal-line {
  max-width: 100%;
  height: 0;
  border: 1px solid rgba(map-get(map-get($paletteNew, gray), gray5), 0.08);
  margin: 24px 0;
}
