.box {
  box-shadow:
    0px 0px 20px rgba(map-get(map-get($palette, secondary), 6), 0.1),
    inset 0px 0px 0px 2px rgba(map-get(map-get($palette, secondary), 6), 0.06);
  border-radius: 12px;
  margin-bottom: 24px;
  overflow: hidden;

  @media screen and (max-width: map-get($breakpoints, small)) {
    &:not(.product-order):not(.agreement):not(.invest-order-box):not(
        .orginiation-order-box
      ):not(.tooltip-box):not(.search-box):not(.message-box) {
      box-shadow: none;
      margin: 12px 0 32px 0;
    }
  }
  .box-title {
    min-height: 56px;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid
      rgba(map-get(map-get($paletteNew, gray), gray3), 0.2);

    &.top-border {
      border-top: 1px solid
        rgba(map-get(map-get($paletteNew, gray), gray3), 0.2);
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 48px;
      padding: 0px;
      border-bottom: 0px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      span:last-of-type {
        margin: 1rem 0;
      }
    }
  }

  .box-body {
    display: flex;
    align-items: left;
    flex-direction: column;
    justify-content: center;

    .content {
      margin: 20px 20px 10px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        margin: 4px 0;
      }
    }
  }

  .box-content {
    padding: 25px 10px;
  }
  .box-item {
    min-height: 85px;
    padding: 0px 25px;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid
      rgba(map-get(map-get($paletteNew, gray), gray3), 0.2);

    @media screen and (max-width: map-get($breakpoints, small)) {
      min-height: 64px;
      padding: 0px;
      border-bottom: 0px;
    }

    &.centered {
      @media screen and (max-width: map-get($breakpoints, small)) {
        justify-content: center;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    &.box-item-spaced {
      justify-content: space-between;
    }

    .box-item-action {
      margin-left: auto;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .box-item-icon img {
      width: 35px;
      height: 35px;

      @media screen and (max-width: map-get($breakpoints, small)) {
        display: none;
      }
    }

    .box-item-description {
      margin: 0 15px 0 30px;
      @media screen and (max-width: map-get($breakpoints,small)) {
        margin: 0 10px 0 0;
      }

      .box-item-sub-title {
        color: map-get(map-get($paletteNew, gray), gray3);
        display: block;

        @media screen and (max-width: map-get($breakpoints, small)) {
          display: none;
        }
      }
    }

    .box-item-column {
      flex-direction: column;
      align-items: baseline;

      &.status-verified {
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
      }
    }

    .verified {
      img {
        @media screen and (max-width: map-get($breakpoints, small)) {
          width: 18px;
          height: 18px;
          margin-top: 2px;
        }
      }
    }
  }
}

.search-box {
  overflow: unset;
}

.box-group {
  display: flex;
  flex-direction: row;
  gap: 32px;
  @media screen and (max-width: map-get($breakpoints, small)) {
    flex-direction: column;
  }
}
