.uphold-support {
  margin-top: -0.4rem;
  text-align: center;
  a {
    text-decoration: underline;
  }
}
.info {
  color: map-get(map-get($paletteNew, gray), gray3);
  margin: 1rem 0;
}

.place-order-box__details.confirm-bucks .confirm-checkbox {
  margin-top: 0px;
  margin-bottom: 10px;

  .children {
    margin-top: 1px;
  }
}
.place-order-box__details {
  .auth-title {
    margin-top: 10px;
  }
  .promo {
    color: map-get(map-get($paletteNew, secondary), royal);
  }
  &.verification {
    .input-group input {
      text-align: center;
      letter-spacing: 2px;
    }
  }
}

@media screen and (max-width: map-get($breakpoints, small)) {
  .order-confirmation {
    .product-header {
      img,
      .product-name,
      .series,
      .price {
        display: none;
      }
    }
    .agreements {
      display: none;
    }
    .order-group {
      .product-order {
        .buy-product {
          padding-bottom: 1rem;
        }
        .place-order-box__details:not(.confirm-checkbox):not(.verification):not(
            .linqto-bucks
          ) {
          min-height: 30px;
          height: auto;
          .max-50 {
            max-width: 50%;
          }
        }

        .place-order-box {
          div.err-msg {
            margin-bottom: 10px;
          }
          .line {
            border-bottom: 0.8px solid
              rgba(map-get(map-get($paletteNew, gray), gray5), 0.1);
          }
        }
      }
    }
  }

  .place-order-m-content {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;

    .agreements {
      display: block;
      margin: 0px;

      .agreement {
        padding: 0.8rem 1.4rem;
      }
    }
  }
}

.order-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: map-get($breakpoints, medium)) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product-header {
    @media screen and (max-width: map-get($breakpoints, medium)) {
      text-align: center;
    }
    @media screen and (max-width: map-get($breakpoints, small)) {
      width: 100%;
      margin-bottom: 24px;
    }
    .icon {
      height: 110px;
      border-radius: 50%;
      @media screen and (max-width: map-get($breakpoints, small)) {
        height: 50px;
      }
    }

    .product-name {
      margin: 0.8rem 0 1.8rem;
    }

    .price {
      margin: 10px 0;

      &.disabled {
        color: map-get(map-get($paletteNew, gray), gray3);
      }
      span {
        color: map-get(map-get($paletteNew, gray), gray5);
      }
    }

    .description {
      width: 17rem;
      color: map-get(map-get($paletteNew, gray), gray5);
    }
  }

  .product-order {
    width: 60%;
    border-radius: 8px;
    max-width: 600px;
    margin-bottom: 0;
    align-self: flex-start;
    @media screen and (max-width: map-get($breakpoints, medium)) {
      width: 100%;
      max-width: 100%;
    }

    .buy-product {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-radius: 5px;
      padding: 1.8rem;
      .title {
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: map-get($breakpoints, small)) {
          flex-direction: column;
          .label {
            margin-top: 10px;
          }
        }
      }
    }

    .place-order-box {
      // height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.8rem;
      &:not(.review) {
        border-top: 1px solid
          rgba(map-get(map-get($paletteNew, gray), gray5), 0.05);
      }
      &.review {
        padding-top: 0px;
      }
      .line {
        width: 100%;
        height: 1px;
        border-bottom: 0.8px solid
          rgba(map-get(map-get($paletteNew, gray), gray5), 0.2);
        margin-top: -0.5rem;
        margin-bottom: 0.5rem;
      }
      &__details {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        &:not(.confirm-checkbox):not(.verification):not(.linqto-bucks) {
          flex-direction: row;
          align-items: center;
          height: 25px;
          color: map-get(map-get($paletteNew, gray), gray5);
        }

        .input-group.checkbox.confirm-checkbox {
          .checkbox-label {
            .children {
              color: map-get(map-get($paletteNew, gray), gray5);
            }
          }
        }

        .linqto-bucks.input-group {
          .children {
            color: map-get(map-get($paletteNew, gray), gray5);
          }
        }

        .slider-btn {
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          &:focus {
            outline: none;
          }
          img {
            width: 30px;
          }
        }
        .slider {
          margin: 0 2rem;
        }
      }
    }

    .confirm-checkbox {
      margin-top: 0;

      .checkbox-label__text,
      .checkbox-label__text span {
        color: map-get(map-get($paletteNew, gray), gray5);
      }
      &.error {
        .checkbox-label {
          &__text,
          &__text span,
          &__text span a {
            color: map-get(map-get($paletteNew, action), errorRed);
          }
        }
      }
    }
  }
}
