.us-documents-container {
  .us-documents-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 54px;
    margin-bottom: 32px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }

  .us-documents-upload {
    display: flex;
    height: 70px;
    @media screen and (max-width: map-get($breakpoints, small)) {
      flex-direction: column;
      height: auto;
    }
    select {
      width: 40%;

      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
        margin-bottom: 8px;
      }
    }
    .upload-container-new {
      width: 100%;

      .dropzone-new {
        width: 100%;
      }
    }
    .file-selected {
      width: 40%;

      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
    }
    .file-new {
      width: 58%;

      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }

      .upload-container-new {
        margin-left: 2%;
        @media screen and (max-width: map-get($breakpoints, small)) {
          margin-left: 0;
        }
      }
    }
  }

  .us-documents-uploaded {
    height: 70px;
    .upload-container-new {
      width: 40%;

      @media screen and (max-width: map-get($breakpoints, small)) {
        width: 100%;
      }
      .dropzone-new {
        width: 100%;
      }
    }
  }

  .add-document-link {
    align-items: center;
    margin-top: 8px;
    display: flex;

    img {
      margin-right: 16px;
    }
  }
}

.uploading {
  height: 70px;
  display: block;
}
