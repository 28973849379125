.promo-input-wrapper {
  width: 100%!important;
  position: relative;

  .apply-btn {
    position: absolute;
    right: 0;
    top: 20px;
    padding: 12px 20px;
    color: map-get(map-get($paletteNew, primary), connectedCobalt);
    cursor: pointer;
  }

  &.disabled {
    .apply-btn {
      cursor: default;
      color: map-get(map-get($paletteNew, gray), gray2);
    }
  }

  &.input-group.promo-input-success {
    input:disabled {
      cursor: default;
      background-color: map-get(map-get($paletteNew, gray), white);
    &.promo-input-disabled {
        background-color: map-get(map-get($paletteNew, gray), gray1);
      }
    }
   
  }
  .promo-name-wrapper {
    position: absolute;
    left: 10px;
    top: 32px;
    border-radius: 8px;
    background-color: map-get(map-get($paletteNew, gray), gray1);
    color: map-get(map-get($paletteNew, gray), gray4);
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0px 8px;

    img {
      cursor: pointer;
    }
  }

  .promo-applied-wrapper {
    position: absolute;
    right: 20px;
    top: 32px;
    color: map-get(map-get($paletteNew, gray), gray2);
    display: flex;
    align-items: center;
  }
}
