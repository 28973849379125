.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .or-svg-container {
    display: flex;
    width: 100vw;
    align-items: center;
    img {
      margin: 12px 9px;
    }
    .or-line {
      border-bottom: 1px solid map-get(map-get($paletteNew, gray), gray4);
      width: 100%;
    }
  }
  .back-to-invest-container {
    width: 100%;
    max-width: 900px;
    & > p {
      display: inline-block;
      margin: 8px 0;
      color: map-get(map-get($paletteNew, gray), gray4);
    }
    & > button {
      height: 56px;
      width: 325px;
      @media screen and (max-width: map-get($breakpoints, small)) {
        height: 48px;
        width: 100%;
      }
    }
  }
}
