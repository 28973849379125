.header-progressbar-wrapper {
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    .progressbar-wrapper {
      order: 1;
    }
    h1 {
      order: 2;
      margin-bottom: 0;
    }
    h3 {
      order: 4;
      margin-top: 24px;
      margin-bottom: 0 !important;
    }
    .mobile-account-email {
      order: 3;
      margin-top: 8px;
    }
  }
}

.progressbar-wrapper {
  display: flex;
  flex-direction: column;
  margin: 48px auto 60px;
  width: 100%;
  @media screen and (max-width: map-get($breakpoints,small)) {
    margin: 24px auto;
  }
  .progressbar-line-bg {
    margin-top: 24px;
    height: 4px;
    width: 100%;
    background-color: rgba(
      map-get(map-get($paletteNew, primary), connectedCobalt),
      0.4
    );
    .progressbar-line {
      height: 4px;
      width: 33%;
      background-color: map-get(map-get($paletteNew, primary), connectedCobalt);
      z-index: 99;
    }
  }
}
hr.title-separator {
  @media screen and (max-width: map-get($breakpoints, small)) {
    display: none;
  }
}
